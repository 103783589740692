import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import completedLessons from '../../../../assets/images/completed-lessons.png';
import video1 from '../../../../assets/videos/video1.mov';
import video2 from '../../../../assets/videos/video2.mov';
import video3 from '../../../../assets/videos/video3.mov';
import { Canvas3D } from '../../../components/Canvas3D';
import { Articles } from '../components/Articles';
import { ButtonPrimaryGradient } from '../components/ButtonPrimaryGradient';
import CardsGroup from '../components/CardsGroup';
import FAQGroup from '../components/FAQGroup';
import Footer from '../components/Footer';
import HeroSectionP from '../components/HeroSection';
import Navigation from '../components/Navigation';
import ReviewCard from '../components/ReviewCard';
import TextCard from '../components/TextCard';
import IFAQItem from '../interfaces/IFAQItem';
import profileIllustration2 from './../../../../assets/images/majka_renata.jpg';
import profileIllustration1 from './../../../../assets/images/majka_sandra.jpg';
import profileIllustration3 from './../../../../assets/images/student-ivan.jpg';
import { setRegistrationModalOpen } from '../../../store/slices/auth/authSlice';
import { useAppDispatch } from '../../../store/hooks';
import useMount from '../../../utils/useMount';
import { Helmet } from 'react-helmet';
import { HR_HR } from '../../../../locales/hr-HR/hr-HR';
import { EN_US } from '../../../../locales/en-US/en-US';
import META_DATA from '../../../constants/metaData';
import ROUTES from '../../../constants/routes';

//add fluid typography
//add trasnlations so text props are passed as translation item
//add image slider
export default function Home() {
    const dispatch = useAppDispatch();

    const { t } = useTranslation();

    const cardText1 = {
        text: t('HOW_IT_WORKS.REVIEW_1.TEXT'),
        name: t('HOW_IT_WORKS.REVIEW_1.NAME'),
        role: t('HOW_IT_WORKS.REVIEW_1.OCCUPATION'),
    };
    const cardText2 = {
        text: t('HOW_IT_WORKS.REVIEW_2.TEXT'),
        name: t('HOW_IT_WORKS.REVIEW_2.NAME'),
        role: t('HOW_IT_WORKS.REVIEW_2.OCCUPATION'),
    };
    const cardText3 = {
        text: t('HOW_IT_WORKS.REVIEW_3.TEXT'),
        name: t('HOW_IT_WORKS.REVIEW_3.NAME'),
        role: t('HOW_IT_WORKS.REVIEW_3.OCCUPATION'),
    };

    const FAQContent: IFAQItem[] = [
        {
            id: 'faq-1',
            question: t('HOW_IT_WORKS.FAQ.QUESTION_1'),
            answer: t('HOW_IT_WORKS.FAQ.ANSWER_1'),
        },
        {
            id: 'faq-2',
            question: t('HOW_IT_WORKS.FAQ.QUESTION_2'),
            answer: t('HOW_IT_WORKS.FAQ.ANSWER_2'),
        },
        {
            id: 'faq-3',
            question: t('HOW_IT_WORKS.FAQ.QUESTION_3'),
            answer: t('HOW_IT_WORKS.FAQ.ANSWER_3'),
        },
        {
            id: 'faq-4',
            question: t('HOW_IT_WORKS.FAQ.QUESTION_4'),
            answer: t('HOW_IT_WORKS.FAQ.ANSWER_4'),
        },
    ];

    useMount(() => {
        window.scrollTo(0, 0);
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!window.Trustpilot) {
        const script = document.createElement('script');
        script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
        script.async = true;
        document.body.appendChild(script);
    }

    const isMobile = window.innerWidth < 800;

    const isHrMeta = window.location.pathname.includes('/hr');
    return (
        <>
            <Helmet>
                <title>{META_DATA.HOME.TITLE[isHrMeta ? 'HR' : 'EN']}</title>
                <meta lang={isHrMeta ? 'hr_HR' : 'en_US'} name="description" content={META_DATA.HOME.DESCRIPTION[isHrMeta ? 'HR' : 'EN']} />
                <link rel="alternate" hrefLang="en_US" href={window.location.origin + ROUTES.HOME.EN} />
                <link rel="alternate" hrefLang="hr_HR" href={window.location.origin + ROUTES.HOME.HR} />
            </Helmet>
            <Navigation />
            {!isMobile && <Canvas3D />}
            <section className="landing__section__first" style={{ zIndex: 20 }}>
                <div className="landing-with-padding">
                    <div className="landing__content">
                        <HeroSectionP title={t('HOW_IT_WORKS.HERO.TITLE')} desc={t('HOW_IT_WORKS.HERO.SUBTITLE')} showBtn={false} />
                        <div className={`landing__section w--100 video-container appear-animation`}>
                            <video className="w--100 video-landing" autoPlay muted loop playsInline>
                                <source src={video1} type="video/mp4" />
                            </video>
                        </div>

                        {/*<TrustPilotWidget ></TrustPilotWidget>*/}
                    </div>
                </div>
            </section>

            <div className="spacer layer"></div>

            <section className="landing__section__middle">
                <div className="landing-with-padding">
                    <div className="carousel-container">
                        <div className="type--wgt--bold carousel-title type--color--white type--center">{t('HOW_IT_WORKS.SUBTITLE_1')}</div>

                        <Articles />
                    </div>
                </div>
            </section>

            <section className="landing__section__second">
                <div className="landing-with-padding">
                    <div className="landing__content">
                        <TextCard
                            descClassName={'type--color--half-transparent'}
                            className="mt-18 type--color--white"
                            title={t('HOW_IT_WORKS.TEXT_CARD_1.TITLE')}
                            desc={t('HOW_IT_WORKS.TEXT_CARD_1.SUBTITLE')}
                        />

                        <div className="landing__section mt-10 w--100">
                            <video className="w--100" autoPlay muted loop playsInline>
                                <source src={video2} type="video/mp4" />
                            </video>
                        </div>

                        <ReviewCard img={profileIllustration1} data={cardText1} className="margin-text-mobile" />
                        <TextCard
                            className="margin-text-mobile"
                            title={t('HOW_IT_WORKS.TEXT_CARD_2.TITLE')}
                            desc={t('HOW_IT_WORKS.TEXT_CARD_2.SUBTITLE')}
                        />
                    </div>
                </div>
            </section>

            <section className="landing__section__third">
                <div className="landing-with-padding">
                    <div className="landing__content">
                        <div className="landing__section margin-text-mobile w--100">
                            <video className="w--100" autoPlay muted loop playsInline>
                                <source src={video3} type="video/mp4" />
                            </video>
                        </div>

                        <ReviewCard img={profileIllustration2} data={cardText2} className="margin-text-mobile" />
                        <TextCard
                            className="margin-text-mobile"
                            title={t('HOW_IT_WORKS.TEXT_CARD_3.TITLE')}
                            desc={t('HOW_IT_WORKS.TEXT_CARD_3.SUBTITLE')}
                        />

                        <div className="landing__section margin-text-mobile w--100">
                            <img src={completedLessons} alt="calendar" className="landing__img" />
                        </div>
                        <ReviewCard img={profileIllustration3} data={cardText3} className="mt-30" />

                        <TextCard
                            className="margin-text-mobile"
                            title={t('HOW_IT_WORKS.TEXT_CARD_4.TITLE')}
                            desc={t('HOW_IT_WORKS.TEXT_CARD_4.SUBTITLE')}
                        />
                        <CardsGroup showCreditCards />
                        <div className="landing__section mt-30">
                            <a
                                id="zapocni-danas-how-it-works-1"
                                className="type--color--white"
                                onClick={() => dispatch(setRegistrationModalOpen(true))}
                            >
                                <ButtonPrimaryGradient className={'btn btn--l type--md h--100'}>{t('HOW_IT_WORKS.BUTTON_1')}</ButtonPrimaryGradient>
                            </a>
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: t('HOW_IT_WORKS.SUBTITLE_2') }} className="type--xxl type--wgt--bold mt-30"></p>
                        <FAQGroup data={FAQContent} />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}
