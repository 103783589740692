import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IUser from '../../../interfaces/IUser';

interface ILoginPayload {
    token: string;
    user: IUser;
}

interface IState {
    token: string | null;
    user: IUser | null;
    serverVersion: string | null;
    registrationModalOpen: boolean;
    confirmationModalOpen: boolean;
    loginModalOpen: boolean;
    resetPasswordModalOpen: boolean;
}

const initialState: IState = {
    token: null,
    user: null,
    serverVersion: null,
    registrationModalOpen: false,
    confirmationModalOpen: false,
    loginModalOpen: false,
    resetPasswordModalOpen: false,
};

export interface IVerificationDocumentResponse {
    stripeVerifiedStatus: string;
    stripeVerificationDocumentsUploaded: boolean;
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout(state) {
            state.token = null;
            state.user = null;
        },
        addStripeId(state, action: PayloadAction<string>) {
            state.user!.stripeCustomerId = action.payload;
        },
        connectStripe(
            state,
            action: PayloadAction<{
                stripeConnected: boolean;
                stripeAccountId: string;
            }>
        ) {
            state.user!.stripeConnected = action.payload.stripeConnected;
            state.user!.stripeAccountId = action.payload.stripeAccountId;
        },
        setServerVersion(state, action: PayloadAction<string>) {
            state.serverVersion = action.payload;
        },
        setToken(state, action: PayloadAction<ILoginPayload>) {
            state.token = action.payload.token;
            state.user = action.payload.user;
        },
        updateStateOfVerificationDocument(state, action: PayloadAction<IVerificationDocumentResponse>) {
            state.user!.stripeVerifiedStatus = action.payload.stripeVerifiedStatus;
            state.user!.stripeVerificationDocumentsUploaded = action.payload.stripeVerificationDocumentsUploaded;
        },
        setRegistrationModalOpen(state, action: PayloadAction<boolean>) {
            state.registrationModalOpen = action.payload;
        },
        setConfirmationModalOpen(state, action: PayloadAction<boolean>) {
            state.confirmationModalOpen = action.payload;
        },
        setLoginModalOpen(state, action: PayloadAction<boolean>) {
            state.loginModalOpen = action.payload;
        },
        setResetPasswordModalOpen(state, action: PayloadAction<boolean>) {
            state.resetPasswordModalOpen = action.payload;
        },
    },
});

export const {
    logout,
    addStripeId,
    connectStripe,
    setServerVersion,
    setToken,
    updateStateOfVerificationDocument,
    setRegistrationModalOpen,
    setConfirmationModalOpen,
    setLoginModalOpen,
    setResetPasswordModalOpen,
} = authSlice.actions;
export default authSlice.reducer;
