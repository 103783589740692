import ISubject from '../../../features/landing/components/subjects';

export default interface ITutorItem {
    id: string;
    firstName: string;
    lastName: string;
    profileImage: string;
    slug: string;
    currentOccupation: string;
    aboutTutor: string;
    minPrice: number;
    maxPrice: number;
    averageGrade: number;
    aboutLessons: string;
    completedLessons: number;
    currencyCode: string;
    yearsOfExperience: number;
    subjects: string[];
    numberOfGrades: number;
    videoUrl: string | null;
}

export interface ITutorShowcase {
    subject: ISubject;
    tutors: ITutorItem[];
}

export const getTutorShowcase = async (): Promise<ITutorShowcase[]> => {
    // const response = await fetch((process.env.REACT_APP_API_HOSTNAME || 'https://api.teorem.co') + '/api/v1/tutors/showcase');
    const response = await fetch((process.env.REACT_APP_API_HOSTNAME || 'http://localhost:8080') + '/api/v1/tutors/showcase');
    return await response.json();
};
