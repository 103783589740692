/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/novaOlovka.glb 
*/

import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import React, { useEffect, useRef, useState } from 'react';

export function NovaOlovka(props) {
    const { nodes, materials } = useGLTF('/novaOlovka.glb');

    const [scrollY, setScrollY] = useState(0);
    const ref = useRef(null); // Use Group or Mesh depending on what you're referencing

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    useEffect(() => {
        console.log('ROTATION', props.rotation);
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useFrame(() => {
        if (ref.current) {
            const dirX = props.directionX ? props.directionX : 1;
            const dirY = props.directionY ? props.directionY : 1;
            const rotationFactor = 0.001;
            const moveFactor = 0.0008;
            ref.current.rotation.x = scrollY * rotationFactor;
            ref.current.rotation.y = scrollY * rotationFactor;
            ref.current.position.x = -1.5 + dirX * scrollY * moveFactor;
            ref.current.position.y = 1.75 + dirY * scrollY * moveFactor;
            ref.current.position.z = dirY * scrollY * moveFactor;
        }
    });


    return (
        <group {...props} dispose={null}>
            <group ref={ref} scale={0.0055} rotation={props.rotation}>
                <mesh geometry={nodes.Object_16_1.geometry} material={materials['Floor Boards']} />
                <mesh geometry={nodes.Object_16_2.geometry} material={materials['Custom (4)']} />
                <mesh geometry={nodes.Object_16_3.geometry} material={materials.Material_3} />
                <mesh geometry={nodes.Object_16_4.geometry} material={materials['Custom (3)']} />
                <mesh geometry={nodes.Object_16_5.geometry} material={materials.Metal} />
            </group>
        </group>
    );
}

useGLTF.preload('/novaOlovka.glb');
