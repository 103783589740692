import { useEffect } from 'react';

import LandingWrapper from '../components/LandingWrapper';
import { Helmet } from 'react-helmet';
import META_DATA from '../../../constants/metaData';
import ROUTES from '../../../constants/routes';

const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const isHrMeta = window.location.pathname.includes('/hr');
    return (
        <LandingWrapper>
            <Helmet>
                <title>{META_DATA.PRIVACY.TITLE[isHrMeta ? 'HR' : 'EN']}</title>
                <meta lang={isHrMeta ? 'hr_HR' : 'en_US'} name="description" content={META_DATA.PRIVACY.DESCRIPTION[isHrMeta ? 'HR' : 'EN']} />
                <link rel="alternate" hrefLang="en_US" href={window.location.origin + ROUTES.PRIVACY.EN} />
                <link rel="alternate" hrefLang="hr_HR" href={window.location.origin + ROUTES.PRIVACY.HR} />
            </Helmet>
            <div className="privacy">
                <h1>TEOREM PRIVACY POLICY</h1>
                <p>
                    Teorem is committed to protecting your personal data. We tried to write this Privacy Policy in the most clear and plain language
                    possible. Likewise, we tried to provide you with our privacy commitments in a transparent and easily accessible way. That said, we
                    hope you will get all the needed details to be assured your personal data is safe with us.
                </p>
                <p>
                    Before you give us your consent on the processing of your personal data or provide us with your personal data within any form on
                    our website, we kindly ask you to read this Privacy Policy carefully, so we can be sure that you completely understand the
                    conditions under which we would cooperate.
                </p>
                <p>
                    This Privacy Policy applies to your use of the Services of Teorem, as defined in our
                    <a href={'/en/terms'}> Terms of Service</a>. This Privacy Policy explains when, where, and why we process the personal data of
                    people who use the Teorem Marketplace, how we use it, the conditions under which we may disclose it to others, your rights in
                    respect of your personal data, as well as how we keep it secure.
                </p>
                <h2>GENERAL</h2>
                <p>
                    Access to the Teorem Marketplace (including Website, related software and Teorem mobile device application) is available with or
                    without the necessity to register an account. You may access our Website without registration. To be able to use the Teorem mobile
                    device application you need to register an account first. Therefore, limited use of the Services is always possible without the
                    provision of your personal details (for example, browse the Website, check how Teorem works, look through the list of tutors, read
                    the Teorem Blog, review the variety of services, etc.). However, whenever you access the Website, we collect log information and
                    put cookies on your device, by which we track your activity on the Website. You can always opt out of cookies except those without
                    which the Website cannot work.
                </p>
                <p>
                    When you are a registered user, Teorem may collect from you and process cookie identifiers and the data you specifically provide
                    us with upon registration, including first name, last name, email, phone number, Skype ID, or social network ID (if assigned
                    through Facebook or Google+ or if accessing Google Calendar), time zone, interface language, IP address, and device type.
                </p>
                <p>
                    Specifically, if you are a tutor, we may also collect and process, besides the aforementioned information, the other data provided
                    by you, including zip code, gender, year of birth, city of residence, and education experience.
                </p>
                <p>
                    Registered users may additionally provide profile pictures as well as chosen currency to perform actions pursuant to our{' '}
                    <a href={'/en/refund-and-payment-policy'}> Refund and Payments Policy</a>.
                </p>
                <p>
                    You can find out more about the specific cases of data collection and the purposes of the processing of such data in a separate
                    section of this Privacy Policy.
                </p>
                <p>
                    We may change this Privacy Policy from time to time, so please check this page to ensure that you’re happy with any changes. We
                    will post those changes on this page and update the Privacy Policy modification date at the top of this page. Additional
                    notifications on any amendments will be sent to your email.
                </p>
                <p>
                    We may also occasionally experiment with different features of the Teorem Marketplace which can affect the scope, purposes, or
                    other details of the processing of personal data. In such cases we will provide prior notification and, if necessary, obtain your
                    consent.
                </p>
                <p>
                    Any questions regarding this Policy and our services should be sent to <a href={'mailto:legal@teorem.co'}> legal@teorem.co</a>.
                </p>
                <ul>
                    <h3>1. What is Personal Data and the Processing of Personal Data? Main Definitions</h3>
                </ul>
                <p>
                    <b>Personal data</b> is any information relating to you that alone or in combination with other pieces of information allows a
                    person that collects and processes such information to identify you as an individual.
                </p>
                <p>
                    <b>Processing of the personal data </b> means any operation with your personal data, whether or not by automated means, such as
                    collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by
                    transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.
                </p>
                <p>
                    <b>Data subject</b> is any identified or identifiable natural person whose personal data is processed by the controller
                    responsible for the processing; means you as a user of Teorem.
                </p>
                <p>
                    <b>Consent of the data subject/user</b> is any freely given, specific, informed, and unambiguous indication of the data
                    subject/user's wishes by which he or she, by a statement or by clear affirmative action, signifies agreement to the processing of
                    personal data relating to him or her.
                </p>
                <ul>
                    <h3>2. Teorem’s Lawful Bases for Processing </h3>
                </ul>
                <p>Teorem processes your personal data only when we have lawful bases. Specifically, Teorem processes your personal data if:</p>
                <ul>
                    <li>
                        processing is based on the consent you have given. As such our newsletters, collection, analysis, and publication of user
                        feedback and reviews, analysis of video lessons, your participation in our events and webinars, and placement of different
                        types of cookies, and posting of the sweepstakes winners is based on your consent;
                    </li>
                    <li>
                        processing is necessary to provide our services to you according to our<a href={'/en/terms'}> Terms of Service</a>
                        which constitute a contract between you and us as regards to information services of Teorem;
                    </li>
                    <li>
                        processing is necessary for the purposes of our legitimate interests. Those legitimate interests may include but are not
                        limited to:
                        <ul>
                            <li>compliance with Croatian financial and accounting legislation;</li>
                            <li>
                                the assessment of Teorem’s business efficiency by analyzing the Teorem Marketplace traffic and financial performance
                                indicators;
                            </li>
                            <li>
                                promotion of Teorem through social media networks, media outlets, referees and affiliates, such as bloggers or
                                influencers; this in particular concerns the right of Teorem to use the tutor´s name, profile picture and other
                                details for marketing, advertising or promotional purposes pursuant to the
                                <a href={'/en/terms'}> Terms of Service</a>;
                            </li>
                            <li>user session control and fraud prevention, ensuring the appropriate protection of personal data;</li>
                            <li>tutor verification and development of high-quality learning programs with tutors;</li>
                            <li>
                                enhancing the learning experience for tutors and students by providing AI-powered personalized recommendations,
                                improved lesson planning, and interactive learning tools.
                            </li>
                        </ul>
                    </li>
                    <li>where necessary, compliance with applicable legislation (e.g., data subject request processing);</li>
                    <li>
                        in exceptional cases, to protect your vital interests or vital interests of another natural person; or for the performance of
                        a task carried out in the public interest or in the exercise of official authority vested in us.
                    </li>
                </ul>
                <p>
                    In all cases, we will notify you about the applied legal basis for processing and other relevant details before we start the
                    processing of your personal data on a specific legal basis unless we are not able to do so in accordance with our obligations
                    under any applicable legislation.
                </p>
                <ul>
                    <h3>3. Data We Collect: Scope </h3>
                </ul>
                <p>Teorem collects from you only the following information and only for the stated below purposes: </p>
                <ul>
                    <h4>1) Account for Students</h4>
                </ul>
                <p>
                    Teorem collects and processes personal data submitted by you upon registration and further editing of settings in your Account.
                    For registration purposes, you submit your login credentials (username and password), first name, last name, country, and email.
                    You may also edit your Account anytime with additional information as well as sync your Google and Teorem Calendars. Such
                    additional information may help you to find a suitable time slot with a tutor or to have more visibility of your schedule. At the
                    same time, you are not required to provide additional information not specifically necessary for the registration; it is your
                    choice.
                </p>
                <ul>
                    <h4>2) Account for Tutors</h4>
                </ul>
                <p>
                    Teorem collects and processes personal data submitted by you upon registration and further editing of your profile. For
                    registration purposes, you submit your login credentials (username and password), first name, last name, country, and email. You
                    may also edit your profile anytime with additional information as well as sync your Google and Teorem Calendars. Such additional
                    information may help you to find a suitable time slot with a student or to have more visibility of your schedule. At the same
                    time, you are not strictly required to provide additional information not specifically necessary for the registration. Therefore,
                    it is your choice whether to include additional data in your profile, or not, such as zip code, language skills, education, and
                    work experience.
                </p>
                <p>
                    Some personal data, such as users’ names and location that is listed in your user profiles or derived from IP Addresses may be
                    publicly visible to other users on the Services or to the web. We may implement a feature that permits you to choose what
                    information is made public by changing your privacy setting in “Edit My Profile” within the Service.
                </p>
                <ul>
                    <h4>3) Subscription to the Teorem Blog </h4>
                </ul>
                <p>
                    You may decide to subscribe to the Teorem Blog to receive newsletters and updates from the Teorem Blog. For this, we require you
                    to give us your email.
                </p>
                <ul>
                    <h4>4) Posting of Information </h4>
                </ul>
                <p>
                    We process personal data freely submitted to you on Teorem. This may be a survey and online form you fill in on Teorem, a question
                    or comment left in the Q&A section of our Website, or feedback you provide us with.
                </p>
                <p>
                    Teorem may also process information provided by you (including content and messages) that you post on/through the Teorem
                    Marketplace, including personally identifiable information. By doing so, you acknowledge that any information you share through
                    the Services will be available to Teorem and to other users of the Services (students/tutors). You also acknowledge that parts of
                    your content cannot be deleted from the Service as it may be connected to the Lessons created by you and used by other users
                    (students/tutors).
                </p>
                <p>
                    From our turn and unless you opt out, we retain this information as necessary to resolve disputes, provide customer support and
                    troubleshoot problems as permitted by law. We reserve the right to terminate the accounts of visitors who use these services in a
                    manner inconsistent with the Terms of Service.
                </p>
                <ul>
                    <h4>5) Service Use, Cookies and Other Similar Technologies</h4>
                </ul>
                <p>When you use our Services, we monitor and analyze web traffic and keep track of your behavior.</p>
                <p>
                    Please refer to our <a href={'/en/cookie-policy'}> Cookie Policy</a> for further details as to our use of cookies and similar
                    technologies.
                </p>
                <ul>
                    <h4>6) Billing Information </h4>
                </ul>
                <p>We process the details of transactions you carry out through the Teorem Marketplace (as ID, date and time of transaction).</p>
                <ul>
                    <h4>7) Sensitive Data</h4>
                </ul>
                <p>Unless you expressly agree, Teorem does not process your sensitive data.</p>
                <ul>
                    <h4>8) Pages on Social Networks </h4>
                </ul>
                <p>
                    We maintain Teorem’s official pages on social networks, such as Facebook, TikTok, Instagram and LinkedIn. When you interact with
                    Teorem’s official pages in social networks, such as subscribing to the pages or leaving comments or reactions (e.g., ‘like’ our
                    posts), we can analyze this data to measure our efficiency and conduct an analysis of our social media marketing campaigns in our
                    legitimate interests.
                </p>
                <ul>
                    <h4>9) Tutors Community (for Tutors Only) </h4>
                </ul>
                <p>
                    We maintain a Tutors Community which can be accessed through our Website via Single-Sign-On (SSO). To fulfill purposes specified
                    under subsection (9) of section (4) of this Privacy Policy, we will also collect and process your interaction data and usage data
                    within the Tutors Community.
                </p>
                <ul>
                    <h4>10) Tutors Academy (for Tutors Only)</h4>
                </ul>
                <p>
                    By registering as a tutor, you will be able to access our Tutors Academy and take various courses to gain new skills, improve your
                    methodology, boost your ranking and get the most out of the Teorem Marketplace. We will collect and process your activity data as
                    well as your comments, reviews and survey results to optimize and improve our courses and make them more engaging and effective.
                </p>
                <ul>
                    <h4>11) Information Required for Submitting Tax Documentation</h4>
                </ul>
                <p>
                    To comply with the applicable tax and accounting legislation, we may be required to submit various tax documentation containing
                    your personal data. For submitting tax documentation, we may process your personal data including but not limited to your full
                    name, country of citizenship, permanent residence address, mailing address, date of birth, personal identification number, or
                    foreign tax identifying number.
                </p>
                <ul>
                    <h4>12) Information required for Marketing and Promotion</h4>
                </ul>
                <p>
                    We may collect the tutor's postal address to promote the Tutoring Services that you provide via the Teorem Marketplace and help
                    you reach more prospective students looking for Tutoring Services within a certain geographical area.
                </p>
                <ul>
                    <h4>13) Analysis of Speech Activity</h4>
                </ul>
                <p>
                    When you give/take lessons on the Teorem Marketplace, we may analyze your microphone activity and measure the volume of the sound.
                    We do it to provide you with an overview of your speaking activity and to let you know how active you were during the lesson.
                    Please be assured that we do not record your voice, words, or the content of your conversations.
                </p>
                <ul>
                    <h4>14) Assessment</h4>
                </ul>
                <p>
                    We process your personal data to assess your level of knowledge in a particular subject. For this, we may additionally process
                    your name, email address, and the results of your assessments. Such an assessment may take place before or after you register on
                    the Teorem Marketplace.
                </p>
                <ul>
                    <h4>15) Video Lessons, Sales Calls, Events and Webinars </h4>
                </ul>
                <p>
                    We may process your likeness and interactions in videos when you conduct/take group classes on the Teorem Marketplace, take part
                    in sales calls, or attend our webinars and events. We will not record you with out giving notice first and asking for your consent
                    if required.
                </p>
                <ul>
                    <h4>16) Information Processed by AI-Powered Tools</h4>
                </ul>
                <p>
                    We have integrated certain AI-powered tools from third parties to enhance your experience when you use the Teorem Marketplace. For
                    example, we may use your text prompts and, if you give consent, your lessons transcripts to provide personalized learning
                    recommendations, generate lesson plans, offer grammar explanations, enrich vocabulary cards or messages, suggest exercises and
                    homework, assess language proficiency levels, and enable chatbot functionality for vocabulary practice and grammar inquiries.
                </p>
                <ul>
                    <h3>4. Data We Collect: Purposes</h3>
                </ul>
                <p>
                    We may process the personal data you provided us voluntarily for the purposes defined below. From our perspective, we process your
                    personal data to personalize the use of Teorem to the fullest extent possible and to provide you with high-quality Services under
                    the <a href={'/en/terms'}> Terms of Service</a>.
                </p>
                <ul>
                    <h4>1) Provision of Services</h4>
                </ul>
                <p>
                    Primarily, we process your personal data to authorize access to our Services and to fulfill our obligations under the{' '}
                    <a href={'/en/terms'}> Terms of Service</a> for Services provision. Specifically, we use your personal data upon registration to
                    help others (be it a tutor or a student) find your account or profile, schedule, give and/or take lessons, and track your learning
                    progress.
                </p>
                <p>
                    In order for you to use the Services, you will need to provide payment (e.g. credit card details) and billing information through
                    our partners – third-party payment providers, which process your personal data encrypted and securely. Please see our{' '}
                    <a href={'/en/refund-and-payment-policy'}> Refund and Payment Policy</a>
                    for more information.
                </p>
                <p>We may also ask you to take an assessment test in order to establish your level of knowledge in a subject.</p>
                <p>
                    Please note that without the information provided by you under our <a href={'/en/terms'}> Terms of Service</a>, we will be unable
                    to provide you with the functionality of Teorem Marketplace, connect you with other users, allow you to communicate with them and
                    take/give lessons.
                </p>
                <ul>
                    <h4>2) Payment Services</h4>
                </ul>
                <p>
                    We use third-party payment providers to bill you through an online account payment in lieu of directly processing your
                    credit/debit card information. Your paid balance for one or more lessons is safe in your account as we use an integrated iframe
                    for checkouts from our partners-payment providers (Braintree, Stripe, PayPal) and tutors get the payment for each confirmed lesson
                    securely through third-party payment providers (Braintree, Stripe, PayPal, TransferWise). Correspondingly all transactions are
                    secured as our partners-payment providers use SSL encryption protection. All payment information is stored encrypted and securely
                    by Braintree, a PayPal service. We do not store your payment information on our servers.
                </p>
                <p>
                    We may also use your payment card transaction data as shared by third-party service providers to confirm a specific transaction
                    occurred and that you qualify for a promotional offer; for example, the date and amount of your purchase and the last 4 digits of
                    your card number so that we can verify your purchase with our records.
                </p>
                <ul>
                    <h4>3) Communications</h4>
                </ul>
                <p>
                    We may contact you via email and other ways, including through publicly available notices, for Services-related purposes (welcome
                    letter, security and legal notices, announcements, Teorem updates, reminders, suggestions, etc.). We may also use your data to
                    enable you to communicate with other users through Teorem, including exchanging messages, conducting video calls, and/or leaving
                    feedback about other users.
                </p>
                <p>
                    You may also receive certain push notifications via Teorem mobile device application. You are able to adjust such notifications
                    using the internal settings of your smartphone.
                </p>
                <p>
                    Having received the communications from us, you are always able to opt out of such communications in each message, excluding legal
                    notices, essential reminders/notifications or changes of user settings on Teorem.
                </p>
                <ul>
                    <h4>4) User Research</h4>
                </ul>
                <p>
                    Your contact data (including email, phone number, etc.) may also be used for gathering feedback. We may contact you to participate
                    in research to improve the user experience of our product. The research will be used for internal purposes only, including:
                    <ul>- Feature development and product updates; </ul>
                    <ul>- Marketing activities, including publishing them on Teorem on other information resources;</ul>
                    <ul>- Customer Support updates;</ul>
                    <ul>- Other service updates for improving your experience.</ul>
                </p>
                <p>
                    Your feedback will not be video recorded, audio recorded, or webcast by the Interviewer without obtaining consent from you first.
                    The same applies to your voice and/or image recording.
                </p>
                <ul>
                    <h4>5) Marketing and Promotion of Teorem </h4>
                </ul>
                <p>
                    We may process your first name, last name and email on our mailing list or for the newsletter and send you messages with some
                    commercial or promotional information about our Services. But don’t worry. We will request your consent first, and you may always
                    opt out of our marketing messages using the mechanisms described under the respective link in each message and through the user
                    settings available on Teorem.
                </p>
                <p>
                    If you provide us with your phone number, we may also contact you with some commercial or promotional information about our
                    Services.
                </p>
                <p>
                    To promote the Teorem Marketplace through the blog and influencer channels, we allow individuals to participate in the affiliate
                    program on our Website. When we receive the information to participate in the affiliate program, we process this information to
                    register you as an affiliate and further track the progress of the promotion of the Teorem Marketplace. We may use the postal
                    address collected from you upon your consent in connection with search engine marketing and optimization.
                </p>
                <p>
                    Also, please pay attention that we may use the information provided by you as feedback. The latter, under Section 12 of the{' '}
                    <a href={'/en/terms'}> Terms of Service</a>, may be used by Teorem without any additional compensation to you.
                </p>
                <ul>
                    <h4>6) Behavioral and Social Media Marketing</h4>
                </ul>
                <p>
                    We may share your email address with Google and Meta for the purpose of delivering personalized advertisements to you and engaging
                    in behavioral and social media marketing. We will conduct these marketing activities based on our legitimate interest and with
                    consideration for your interests and privacy.
                </p>
                <ul>
                    <h4>7) Identify Influencers for Potential Collaborations </h4>
                </ul>
                <p>
                    We may use third-party services, such as Captiv8, Inc. and LiveRamp Holdings, Inc. to analyze the information you provide to us at
                    the time of registration (such as your name and other identifiers), execute a matching exercise, and discover our most influential
                    students for the purposes of proposing collaboration with us. In doing so, we may receive some of your personal data from Captiv8,
                    Inc. (such as your social media profiles) and form a joint controllership over your data. In case we discover that you are an
                    influencer and decide to propose collaboration, we will provide you with a short privacy notice explaining this processing
                    activity in detail.
                </p>
                <ul>
                    <h4>8) Analytics and Development </h4>
                </ul>
                <p>
                    In order to establish new ways to improve the Teorem Marketplace, find the points of interest in our Services and for the purposes
                    of analytics, we monitor and analyze the personal data you provide us with, including information you provide upon registration,
                    the web traffic, cookies and usage data, such as the parts of our Services you visit, the date and duration of your visit, your
                    location, type of the device you used during your visit and other relevant data on the use of the Teorem Marketplace. For these
                    purposes, we use services such as Google Analytics for Firebase, Hotjar HeatMaps & Recordings, Amplitude Analytics, Branch.io,
                    Braze, and Intercom, the use of which ensures compliance with our privacy commitments.
                </p>
                <p>
                    For the purposes indicated above, we may also run occasional research activities, including personalized “A/B” tests. During the
                    tests, we will display different content to the users based on various criteria, such as their country. The purpose of such tests
                    is to understand user engagement and satisfaction with certain features in order to improve the user experience, develop new
                    useful features, and streamline our Services.
                </p>
                <p>
                    We may also carry out polls and surveys through Teorem using external GDPR-compliant services. You are not obliged to respond to
                    them and you may opt out of such polls and surveys through user settings. In order to inform, optimize and serve you relevant
                    advertising based on your past use of our Services, we may process your usage data and cookies. Please, follow our{' '}
                    <a href={'/en/cookie-policy'}> Cookie Policy</a> for additional information.
                </p>
                <ul>
                    <h4>9) Tutor Verification and Program Preparation</h4>
                </ul>
                <p>
                    To ensure a high quality of lessons given by tutors on Teorem, we collect and process tutor credentials to verify their
                    eligibility to teach subjects claimed by them. Besides that, we cooperate with the tutors and use their information to help them
                    develop learning programs.
                </p>
                <p>
                    For the purposes of analytics, we additionally process tutors’ registration data to automate and improve the tutor verification
                    process.
                </p>
                <ul>
                    <h4>10) Improvement of Tutors’ Ranking Algorithm </h4>
                </ul>
                <p>
                    We gather and use various metric-based data to enhance the tutors' ranking algorithm and improve your experience on the basis of
                    our legitimate interest. This data may also consist of personal data that the tutors and students provide on the Teorem
                    Marketplace. You can refer to Section 3.6 of our <a href={'/en/terms'}> Terms of Service</a>
                    to learn more about the categories of data we may collect.
                </p>
                <ul>
                    <h4>11) Managing Tutors Community </h4>
                </ul>
                <p>
                    We process the email, interaction and usage data of tutors participating in Tutors Community to provide them with the ability to
                    come together and share their best practices, get official up-to-date information, and interact with each other and with Teorem
                    based on our legitimate interest in community building. We also use the collected data for the purposes of moderation and
                    statistics and to improve the tutor's user experience.
                </p>
                <p>
                    Rest assured that access to the personal data submitted by you to the Tutors Community will be restricted to service providers and
                    employees on a need-to-know basis.
                </p>
                <ul>
                    <h4>12) Support and Platform Supervision </h4>
                </ul>
                <p>
                    In case of any disputes on Teorem and other Services-connected issues, we may use data (including communications) to resolve such
                    matters, supervise user behavior on the Teorem Marketplace, and respond to any complaints or requests.
                </p>
                <ul>
                    <h4>13) Events and Webinars Conducted by Teorem </h4>
                </ul>
                <p>
                    We process certain payment information to comply with applicable legal requirements in the field of finances and/or accounting. We
                    also may process the data collected on the Teorem Marketplace in connection with legal requests we receive from you or any other
                    competent person and/or body, and we may use this information to exercise, defend and/or establish our legal rights.
                </p>
                <ul>
                    <h4>15) Fraud Prevention </h4>
                </ul>
                <p>
                    Certain information, such as your log information, payment information and your behavior on the Teorem Marketplace are subject to
                    our supervision to prevent fraudulent activities and assist you in managing your user sessions.
                </p>
                <ul>
                    <h4>16) Changes</h4>
                </ul>
                <p>
                    We may change the Teorem Privacy Policy from time to time. Upon any changes to this Policy, your rights may not be reduced unless
                    you provide explicit consent. The latest version is posted on this page. If we make substantial changes, we will notify you by
                    sending an email.
                </p>
                <ul>
                    <h3>5. How Is Personal Data Shared? </h3>
                </ul>
                <p>
                    In order to provide high-quality services, Teorem hires people, enters into agreements with independent contractors as well as
                    cooperates with other services providers, companies and organizations strictly under data processing agreements (DPA) or Standard
                    Contractual Clauses (SCCs). For those reasons, some of your personal data can be passed to the persons mentioned. Teorem uses only
                    secure places of storage, such as those provided by Microsoft Azure, with servers located in Germany and the United States.
                </p>
                <ul>
                    <h4>1) Categories of Third-Party Service Providers</h4>
                </ul>
                <ul>
                    <li>Analytics services</li>
                    <p>
                        Personal data shared with this category of third-party service providers may include the information described in subsections
                        5 and 14 of section 3 of this Privacy Policy.
                    </p>
                    <li>Social network platforms and search engines</li>
                    <p>
                        Personal data shared with this category of third-party service providers may include the information described in subsection 7
                        of section 4 and subsection 2 of section 5 of this Privacy Policy.
                    </p>
                    <li>Customer communication solutions</li>
                    <p>
                        Personal data shared with this category of third-party service providers may include the information described in subsection 4
                        of section 3 of this Privacy Policy.
                    </p>
                    <li>User research tools</li>
                    <p>
                        Personal data shared with this category of third-party service providers may include the information described in subsection 5
                        of section 4 of this Privacy Policy.
                    </p>
                    <li>Services to manage emails and send out push notifications</li>
                    <p>
                        Personal data shared with this category of third-party service providers may include your contact details and the personal
                        identifiers you provide to us.
                    </p>
                    <li>SMS providers for mobile marketing and notifications</li>
                    <p>
                        Personal data shared with this category of third-party service providers may include your contact details and the personal
                        identifiers you provide to us.
                    </p>
                    <li>Payment services providers</li>
                    <p>
                        Personal data shared with this category of third-party service providers may include the information described in subsection 6
                        of section 3 of this Privacy Policy.
                    </p>
                    <li>AI-powered service providers</li>
                    <p>
                        Personal data shared with these categories of third-party service providers may include the information described in
                        subsections 16 and 17 of section 3 of this Privacy Policy.
                    </p>
                    <li>Providers of video conferencing, speech-to-text, and conversation analytics tools</li>
                    <p>
                        Personal data shared with these categories of third-party service providers may include the information described in
                        subsection 16 of section 3 of this Privacy Policy.
                    </p>
                    <li>Influencer marketing solution providers to identify and engage influencers</li>
                    <p>
                        Personal data shared with this category of third-party service providers may include your name and other identifiers described
                        in subsections 1 and 2 of section 3 of this Privacy Policy.
                    </p>
                    <li>Language assessment tools</li>
                    <p>
                        Personal data shared with this category of third-party service providers may include the information described in subsection
                        15 of section 3 of this Privacy Policy.
                    </p>
                    <li>Search engine optimization and marketing tools</li>
                    <p>
                        Personal data shared with this category of third-party service providers may include the information described in subsection
                        13 of section 3 of this Privacy Policy.
                    </p>
                    <li>Services to manage Tutors Academy</li>
                    <p>
                        Personal data shared with this category of third-party service providers may include the information described in subsection
                        11 of section 3 of this Privacy Policy.
                    </p>
                    <li>Services to manage Tutors Community</li>
                    <p>
                        Personal data shared with this category of third-party service providers may include the information described in subsection
                        10 of section 3 and subsection 10 of section 4 of this Privacy Policy.
                    </p>
                </ul>
                <ul>
                    <h4>2) Use of Social Network Platforms</h4>
                </ul>
                <p>We use social network platforms like Facebook, Instagram, LinkedIn, and TikTok to maintain Teorem’s social media pages.</p>
                <p>
                    Please note that LinkedIn acts both as a processor engaged by Teorem and as an independent controller of your personal data.
                    Additionally, TikTok and Teorem independently process your personal data as independent controllers.
                </p>
                <p>
                    As for Teorem’s Facebook and Instagram pages, Meta Platforms, Inc. (“Meta”) is a joint controller for the processing of personal
                    data. In particular, Meta and Teorem are joint controllers for the pages' insight data and statistics, your reactions and comments
                    under our posts, and other information you make available to Teorem upon contacting us.
                </p>
                <p>
                    You may find more information on the Meta joint controllership arrangement at the following link:
                    <a href={'https://www.facebook.com/legal/terms/page_controller_addendum'}> Information about Page Insights</a>.
                </p>
                <p>
                    You may contact us with any questions related to your activity on Teorem’s social media pages on the above-mentioned social
                    network platforms or our processing of your personal data.
                </p>
                <p>The service providers mentioned under subsections 1 and 2 will help us to ensure that your data is secure within Teorem.</p>
                <p>
                    Teorem has contractors and subsidiaries in other countries, including those outside the European Economic Area, who may have
                    access to your personal data. At the same time, in the cases when we pass your personal data to other persons, we ensure that your
                    personal data is being protected and used only within the purposes specified in this Privacy Policy. This is achieved by using
                    only certified services and products, signing data processing agreements for the protection of personal data with contractors and
                    partners (including the Standard Contractual Clauses adopted by the European Commission and compliant with the EU data protection
                    laws), as well as taking technical measures to ensure the information is protected when stored, used and while being processed and
                    transferred.
                </p>
                <ul>
                    <h4>3) Data Transfer in Case of Change of Control or Asset Disposition</h4>
                </ul>
                <p>
                    In the event of any merger, acquisition, sale or change of control, we’ll continue to ensure the confidentiality of your personal
                    data and give affected users notice before personal data is transferred or becomes subject to a different privacy policy.
                </p>
                <ul>
                    <h4>4) Teorem Independent Contractors </h4>
                </ul>
                <p>
                    We work with independent contractors who help us provide you with our Services. Specifically, we may engage such contractors to
                    assist us with data analytics and software development, customer support, promotion, marketing, user research, as well as
                    financial and legal matters. These contractors will only have access to your personal data on the need-to-know basis and under
                    strict privacy and security obligations.
                </p>
                <ul>
                    <h4>5) Other Users of the Teorem Marketplace </h4>
                </ul>
                <p>
                    Your personal data may also be made visible to visitors and users of the Teorem Marketplace. This can happen via personal contacts
                    (between student and tutor), or in connection with the social features of the Teorem Marketplace. For example, your profile
                    picture and name may be visible if you have signed up for a group class or if you left a tutor review.
                </p>
                <ul>
                    <h4>6) How Long Will Personal Data be Processed and Stored by Teorem? </h4>
                </ul>
                <p>Your personal data will be stored by Teorem no longer than necessary for the purposes for which the personal data is processed.</p>
                <p>
                    Generally, Teorem retains your personal data as long as your account is active. If your last activity on Teorem was more than 3
                    years ago, your account will be considered expired. In this case or when you decide to delete your Teorem account, we undertake to
                    remove your personal data from our active systems and servers within 90 days or earlier.
                </p>
                <p>
                    However, in case of conflict situations in progress, Teorem may retain your personal data for longer in order to be able to
                    establish, exercise or defend legal claims. Upon the settlement of the conflict situation, all personal data gets deleted if the
                    standard retention period has expired.
                </p>
                <p>
                    We may also retain some of your personal data for longer if we need it to comply with the applicable legal, regulatory, tax,
                    accounting or other requirements.
                </p>
                <p>
                    Personal Data collected when you registered on our mailing list or for the newsletter will be stored as long as you are interested
                    in receiving our emails. We consider that you’re interested in this until you unsubscribe from the mailing list or the newsletter
                    by clicking the respective link contained in each of our emails.
                </p>
                <p>
                    If you have registered for any of our events or webinars using your account credentials, we will merge and store this information
                    along with your account data. If you have used other contact details, we will delete them within 30 days after conducting the
                    event or webinar.
                </p>
                <p>
                    After the expiration of the retention period, Teorem shall terminate the processing of your personal data unless otherwise set by
                    the relevant legislation.
                </p>
                <ul>
                    <h4>7) Your Rights as Data Subject Regarding Your Personal Data Teorem Processes</h4>
                </ul>
                <b>1. Right of Confirmation</b>
                <p>You can obtain confirmation from Teorem as to whether personal data concerning you is being processed.</p>
                <b>2. Right of Access</b>
                <p>You can access your personal data processed by Teorem.</p>
                <b>3. Right to Rectification</b>
                <p>You can ask Teorem to have incomplete personal data requiring corrections completed.</p>
                <b>4. Right to Erasure (Right to Be Forgotten)</b>
                <p>You can request Teorem to erase personal data. In most cases, Teorem will erase it unless otherwise required by legislation.</p>
                <b>To delete your personal data, you can:</b>
                <li>
                    Contact us by email at <a href={'mailto:support@teorem.co'}> support@teorem.co</a>. We will respond to your request to delete your
                    information within 30 days and notify you of the outcome.
                </li>
                <li>Delete specific items from your account (such as your payment method, etc.) by going to your account “Settings” page.</li>
                <b>5. Right of Restriction of Processing </b>
                <p>
                    You can contest the accuracy of your personal data or in case Teorem is not interested in processing your personal data any
                    longer, but you want Teorem to do this for different reasons, for example, to bring a claim against somebody and, instead of the
                    erasure of information, its processing will be restricted.
                </p>
                <b>6. Right to Data Portability</b>
                <p>
                    You can have your personal data transmitted directly from one controller to another where technically feasible, and when doing so
                    does not adversely affect the rights and freedoms of others.
                </p>
                <b>7. Right to Object to Processing of Your Personal Data by Teorem </b>
                <p>
                    You can object to our processing of your personal data when the processing is related to the performance of our task carried out
                    in the public interest or the exercise of official authority vested in us. The other case is if we process your data for the
                    purposes of the legitimate interests pursued by us or by a third party and you believe that such interests are overridden by your
                    interests or fundamental rights and freedoms. If you make a request with an objection to processing, we will no longer process the
                    personal data unless we can demonstrate compelling legitimate grounds for the processing.
                </p>
                <b>8. Right to Withdraw Consent at any Time by Contacting Us </b>
                <p>
                    After we at Teorem receive a withdrawal request from you, we will process it in a timely manner, and we will no longer process
                    your personal data unless otherwise set by law.
                </p>
                <p>
                    If one of the aforementioned reasons applies, please contact us, as instructed at the end of this Privacy Policy. We shall
                    promptly ensure that the request is complied with immediately. These requests are free of charge.
                </p>
                <p>
                    If you believe that our use of your data violates your rights, you can lodge a complaint with the competent data protection
                    authority.
                </p>
                <p>
                    Please note that when the processing of your personal data is carried out for direct marketing purposes, you have the right to
                    object at any time to such processing without providing any justification and we will no longer process your data for such direct
                    marketing purposes.
                </p>
                <ul>
                    <h4>8) Other Privacy Commitments</h4>
                </ul>
                <b>1. Cross-border Transfers </b>
                <p>
                    We process your personal data both within the European Union and outside the European Union. Although countries where we process
                    data may have different laws, we take measures to ensure high standards of data privacy and compliance (namely, as provided in
                    Section 5 of this Privacy Policy).
                </p>
                <b>2. Time for Reply and Reaction</b>
                <p>
                    Teorem will provide information on action taken on your request related to your rights specified above within one month of receipt
                    of the request for the longest. That period may be extended by two further months if Teorem is overwhelmed by the number of
                    requests or the request at issue is complicated and requires a lot of action. Teorem will inform you of any such extension within
                    one month of receipt of the request, together with the reasons for such delay.
                </p>
                <b>3. Security</b>
                <p>
                    Teorem takes technical and organizational measures to ensure the information is processed in a manner that ensures appropriate
                    security of information, including protection against unauthorized or unlawful processing and accidental loss, destruction or
                    damage. For example, Teorem uses verified contractors that might have access to the data with which the relevant data processing
                    agreements are signed.
                </p>
                <b>4. Accessing Account to Provide Support </b>
                <p>
                    In exceptional circumstances, such as required customer support, our support specialists may need to access your account. When
                    such an occasion occurs, they will be able to see the personal data submitted to your account, including your scheduled personal
                    events, if your Google Calendar and Teorem Calendar are connected.
                </p>
                <p>
                    We undertake to always ask for your permission to access your account before we start troubleshooting. Prior to granting us your
                    permission, you might also consider editing your Google Calendar privacy settings with Google Calendar Help Center to hide your
                    personal events from us.
                </p>
                <p>
                    Please note that if you do not want to grant your permission to access your account, it may affect the time and quality of the
                    provision of customer support.
                </p>
                <b>5. Children’s Privacy </b>
                <p>
                    The privacy of children is one of our concerns. Teorem Services are not intended for use by children under the age of 18 without
                    the supervision of their parent or legal guardian. If you are under the age of 18 you will need to ask your parent/legal guardian
                    to set up an account on the Teorem Marketplace that you may use under their supervision.
                </p>
                <p>
                    We do not knowingly collect information from children under the age of 13 and discourage the use of Teorem Services by children
                    under this age. We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce our
                    Privacy Policy by instructing their children never to provide information on the Teorem Marketplace without their permission.
                </p>
                <b>6. Breach Notifications </b>
                <p>
                    If there is a personal data breach that is likely to result in a high risk of adversely affecting your rights as the data subject,
                    we would inform you and the respective data protection agencies as to the accidents without undue delay. We would also do our best
                    to minimize any such risks.
                </p>
                <b>7. “DoNotTrack” Requests </b>
                <p>
                    Teorem does not share personal data with third parties for their direct marketing purposes. Also, our Services do not support “Do
                    Not Track” requests.
                </p>
                <b>8. Automated Decision-making and Profiling </b>
                <p>
                    As a part of the Teorem Marketplace functionality, we process the personal data of our tutors and students in a way that might be
                    considered profiling.
                </p>
                <p>
                    Specifically, we use machine-learning algorithms that analyze various metric-based data and determine the tutor´s ranking on the
                    Teorem Marketplace and their visibility to students. This is done to increase the matching efficiency between students and tutors
                    on the Teorem Marketplace and allow tutors to connect with the optimal number of students who best fit their way of working.
                </p>
                <p>
                    Please note that this type of processing is necessary to provide our Services. To know more about this process and the logic
                    involved, please refer to Section 3.6 of our <a href={'/en/term'}> Terms of Service</a>.
                </p>
                <p>
                    If you want to request human intervention, express your point of view, or contest the decisions made by the algorithms, please
                    send us an email to <a href={'mailto:legal@teorem.co'}> legal@teorem.co</a>.
                </p>
                <b>9. Sale of Personal Data </b>
                <p>We do not sell your personal data and the personal data of any minors under 16 years of age.</p>
                <ul>
                    <h4>9) Legal Matters</h4>
                </ul>
                <p>
                    Teorem considers your use of the Services to be private. However, we may disclose your personal data stored in our databases in
                    order to:
                </p>
                <ul>1) Comply with the law or legal process served on us;</ul>
                <ul>
                    2) Enforce and investigate potential violations of this Privacy Policy; including the use of the Services to participate in or
                    facilitate activities that violate the law;
                </ul>
                <ul>3) Investigate potential fraudulent activities; or</ul>
                <ul>4) Protect the rights, property, or safety of Teorem, its employees, customers or the public.</ul>
                <p>
                    We hope this Privacy Policy helps you to understand how your personal data will be dealt with at Teorem. We will take care of your
                    personal data, so you can use our Services and not be concerned about your privacy. Should you have any questions, e-mail us at{' '}
                    <a href={'mailto:support@teorem.co'}> support@teorem.co</a>.
                </p>
                <h3>Your Teorem Team</h3>
            </div>
        </LandingWrapper>
    );
};

export default Privacy;
