import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { ButtonPrimaryGradient } from './ButtonPrimaryGradient';

interface Props {
    title: string;
    desc: string;
    showBtn: boolean;
}

const HeroSectionPricing = (props: Props) => {
    const { title, desc, showBtn } = props;
    const { t } = useTranslation();

    const titleArr = title.split(' ');
    const isMobile = window.innerWidth < 766;

    return (
        <div className="landing__hero mt-4">
            <div className="landing__hero__title animated-title">
                {titleArr.map((word, index) => (
                    <span key={word} className={'landing--fluid--title margin-span-title'} dangerouslySetInnerHTML={{ __html: word + '' }} />
                ))}
            </div>

            <div className="landing__hero__subtitle landing--fluid--sm type--color--secondary" style={{ maxWidth: '800px' }}>
                <ul className={`pl-0 flex flex--col type--left flex--start ${isMobile ? 'flex--ai--start type--base' : ''} `}>
                    <li className={'flex flex--row flex--center mb-1'}>
                        <IoIosCheckmarkCircle size={25} className={'mr-2'} />
                        <span>{t('PRICING.HERO.LABEL_1')}</span>
                    </li>
                    <li className={'flex flex--row flex--center mb-1'}>
                        <IoIosCheckmarkCircle size={25} className={'mr-2'} />
                        <span>{t('PRICING.HERO.LABEL_2')}</span>
                    </li>
                    <li className={'flex flex--row flex--center mb-1'}>
                        <IoIosCheckmarkCircle size={25} className={'mr-2'} />
                        <span>{t('PRICING.HERO.LABEL_3')}</span>
                    </li>
                    {/*<li>15% popusta na prvo predavanje</li>*/}
                </ul>
            </div>
            {showBtn ? <ButtonPrimaryGradient className='btn btn--lg mt-10'>Try for free</ButtonPrimaryGradient> : <></>}
        </div>
    );
};

export default HeroSectionPricing;
