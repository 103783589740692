import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import IFAQItem from '../interfaces/IFAQItem';

interface Props {
    data: IFAQItem;
}

const FAQItem = (props: Props) => {
    const { t } = useTranslation();
    const { question, answer, link, key } = props.data;

    const [isActive, setIsActive] = useState<boolean>(false);

    return (
        <div>
            <div className="flex--primary p-3 p-md-6 cur--pointer" onClick={() => setIsActive(!isActive)}>
                <div dangerouslySetInnerHTML={{ __html: question }} className="flex--grow type--color--secondary"></div>
                <div className="flex--shrink">
                    <i className={`icon icon--base icon--black ${isActive ? 'icon--minus' : 'icon--plus'}`}></i>
                </div>
            </div>
            {isActive &&
                (link ? (
                    <div className="p-3 pt-0 p-md-6 type--md type--color--secondary">
                        <Trans i18nKey={key} t={t}>
                            x<Link to={link}>y</Link>
                        </Trans>
                    </div>
                ) : (
                    <div dangerouslySetInnerHTML={{ __html: answer }} className="p-3 pt-0 p-md-6 type--md type--color--secondary" />
                ))}
        </div>
    );
};

export default FAQItem;
