const META_DATA = {
    HOME: {
        TITLE: {
            EN: 'Tailored 1-to-1 online tutoring & homework help | Teorem',
            HR: 'Najbolje 1-na-1 online instrukcije i pomoć s učenjem | Teorem',
        },
        DESCRIPTION: {
            EN: 'Teorem provides 1-to-1 private tutoring, homework help, test prep, and virtual learning for all students. - The perfect tutor for everyone - A tailor-made learning plan - Virtual classroom - Start today',
            HR: 'Teorem nudi 1-na-1 online instrukcije, pomoć s učenjem, pomoć sa zadaćom, pripremanje za ispite, i virtualno učenje za sve učenike. - Savršen instruktor za bilokoga - Plan učenja stvoren za Vas - Virtualna učionica - Započni danas',
        },
    },
    PRICING: {
        TITLE: {
            EN: 'High quality online tutoring prices | Teorem',
            HR: 'Cijene visokokvalitetnih online instrukcija | Teorem',
        },
        DESCRIPTION: {
            EN: 'Prices for 1-to-1 50-minute lessons start at $12.50, with the average price of $25.40, depending on the tutor and learning plan. Get more pricing info by contacting a tutor.',
            HR: 'Cijene za 1-na-1 50-minutne instrukcije počinju od HRK 60, s prosječnom cijenom od HRK 80, ovisno o instruktoru i planu učenja. Saznajte više o cijenama tako da kontaktirate naše instruktore.',
        },
    },
    BECOME_TUTOR: {
        TITLE: {
            EN: 'Become an online tutor | Teorem',
            HR: 'Postani online instruktor | Teorem',
        },
        DESCRIPTION: {
            EN: 'How to become an online tutor with Teorem - 1. Register to become a tutor - 2. Complete your subject quiz - 3. Prove your identity - 4. Start tutoring',
            HR: 'Kako postati online instruktor na Teoremu - 1. Registriraj se da postaneš instruktor - 2. Završi kviz o svom predmetu - 3. Dokaži svoj identitet - 4. Započni davat instrukcije',
        },
    },
    TERMS: {
        TITLE: {
            EN: 'Terms of Service | Teorem',
            HR: 'Uvjeti korištenja | Teorem',
        },
        DESCRIPTION: {
            EN: "Welcome to Teorem! These terms of service outline the rules and regulations for the use of Teorem's website.",
            HR: 'Dobrodošli na Teorem! Ovi uvjeti korištenja definiraju pravila korištenja Teoremove webstranice.',
        },
    },
    PRIVACY: {
        TITLE: {
            EN: 'Privacy Policy | Teorem',
            HR: 'Politika privatnosti | Teorem',
        },
        DESCRIPTION: {
            EN: 'One of our main priorities at Teorem is the privacy of our visitors. This Privacy Policy  contains types of information that is collected and recorded by Teorem and how we use it.',
            HR: 'Jedan od naših glavnih prioriteta u Teoremu je privatnost naših posjetitelja. Ova Politika privatnosti sadrži tipove podataka koji su skupljani i spremani od strane Teorema, te na koji ih način koristimo.',
        },
    },
    SCHOOL: {
        TITLE: {
            EN: 'Teorem | Postani online instruktor L#001',
            HR: 'Teorem | Postani online instruktor L#001',
        },
        DESCRIPTION: {
            EN: 'Teorem | Postani online instruktor L#001',
            HR: 'Teorem | Postani online instruktor L#001',
        },
    },
    REFUND: {
        TITLE: {
            EN: 'Refund and Payment Policy | Teorem',
            HR: 'Politika povrata i plaćanja | Teorem',
        },
        DESCRIPTION: {
            EN: 'Your purchase of and payment for lessons/group classes shall be regulated by this Refund and Payment Policy.',
            HR: 'Politika povrata i plaćanja | Teorem',
        },
    },
    CONDUCT: {
        TITLE: {
            EN: 'User Code of Conduct | Teorem',
            HR: 'Pravila ponašanja | Teorem',
        },
        DESCRIPTION: {
            EN: 'In using the Teorem Marketplace, you hereby acknowledge your understanding of this User Code of Conduct and agree to it.',
            HR: 'Pravila ponašanja | Teorem',
        },
    },
    TAX: {
        TITLE: {
            EN: 'Tax | Teorem',
            HR: 'Porez | Teorem',
        },
        DESCRIPTION: {
            EN: '',
            HR: '',
        },
    },
    LEGAL_CENTER: {
        TITLE: {
            EN: 'Legal center | Teorem',
            HR: 'Pravni centar | Teorem',
        },
        DESCRIPTION: {
            EN: '',
            HR: '',
        },
    },
    FALLBACK: {
        TITLE: {
            EN: 'Teorem',
            HR: 'Teorem',
        },
        DESCRIPTION: {
            EN: 'Teorem provides 1-to-1 private tutoring, homework help, test prep, and virtual learning for all students. - The perfect tutor for everyone - A tailor-made learning plan - Virtual classroom - Start today',
            HR: 'Teorem nudi 1-na-1 online instrukcije, pomoć s učenjem, pomoć sa zadaćom, pripremanje za ispite, i virtualno učenje za sve učenike. - Savršen instruktor za bilokoga - Plan učenja stvoren za Vas - Virtualna učionica - Započni danas',
        },
    },
};

export default META_DATA;
