import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICountry } from './countryService';
import LOCAL_STORAGE_KEYS from '../../../constants/localStorageKeys';

export interface ICountryMarketState {
    countries: ICountry[];
    selectedCountry: ICountry | null;
}

const initialCountry = localStorage.getItem(LOCAL_STORAGE_KEYS.PREFERRED_COUNTRY);

const initialState: ICountryMarketState = {
    countries: [],
    selectedCountry: initialCountry ? JSON.parse(initialCountry) : null,
};

export const countryMarketSlice = createSlice({
    name: 'countryMarket',
    initialState,
    reducers: {
        setCountries(state, action: PayloadAction<ICountry[]>) {
            state.countries = action.payload;
        },

        setSelectedCountry(state, action: PayloadAction<ICountry>) {
            state.selectedCountry = action.payload;
            localStorage.setItem(LOCAL_STORAGE_KEYS.PREFERRED_COUNTRY, JSON.stringify(action.payload));
        },
    },
});

export const { setCountries, setSelectedCountry } = countryMarketSlice.actions;

export default countryMarketSlice.reducer;
