import TRIGGER_IDS from '../../../../../constants/triggerIds';
import { Role } from '../../../../../lookups/role';

const TRIGGER_MAP: Record<Role, string> = {
    [Role.Student]: TRIGGER_IDS.STUDENT_FINISH_REGISTRATION,
    [Role.Tutor]: TRIGGER_IDS.TUTOR_FINISH_REGISTRATION,
    [Role.Parent]: TRIGGER_IDS.PARENT_FINISH_REGISTRATION,
    [Role.Child]: '',
    [Role.SuperAdmin]: '',
};

export default TRIGGER_MAP;
