import { useEffect } from 'react';

import LandingWrapper from '../components/LandingWrapper';
import { Helmet } from 'react-helmet';
import META_DATA from '../../../constants/metaData';
import ROUTES from '../../../constants/routes';

const Conduct = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const isHrMeta = window.location.pathname.includes('/hr');
    return (
        <LandingWrapper>
            <Helmet>
                <title>{META_DATA.CONDUCT.TITLE[isHrMeta ? 'HR' : 'EN']}</title>
                <meta lang={isHrMeta ? 'hr_HR' : 'en_US'} name="description" content={META_DATA.CONDUCT.DESCRIPTION[isHrMeta ? 'HR' : 'EN']} />
                <link rel="alternate" hrefLang="en_US" href={window.location.origin + ROUTES.USER_CODE_OF_CONDUCT.EN} />
                <link rel="alternate" hrefLang="hr_HR" href={window.location.origin + ROUTES.USER_CODE_OF_CONDUCT.HR} />
            </Helmet>
            <div className="privacy">
                <h1>
                    <strong>User Code of Conduct</strong>
                </h1>
                <p>In using the Teorem Marketplace, you hereby acknowledge your understanding of this User Code of Conduct and agree to:</p>
                <ul>
                    a) Comply with all applicable laws, including, without limitation, privacy laws, intellectual property laws, anti-spam laws, tax
                    laws, etc.;
                </ul>
                <ul>b) Provide accurate information to Teorem and keep it updated;</ul>
                <ul>c) Use the Services and the Teorem Marketplace in a manner that is lawful, relevant, and proper to the applicable laws;</ul>
                <ul>d) Follow the standards of conduct as set forth herein.</ul>
                <p>
                    Any use of the Teorem Marketplace that Teorem, in its sole discretion, finds inappropriate and/or offensive{' '}
                    <u>may result in suspension and/or termination of a User account with or without notice.</u>
                </p>
                <p>
                    You agree to be abided by the following standards of conduct, and you agree that you will not and will not authorize or facilitate
                    any attempt by another person to:
                </p>
                <ul>1. Provide or accept tutoring services from any Teorem Users bypassing the Teorem Marketplace;</ul>
                <ul>
                    2.{' '}
                    <u>
                        Receive payments from the Students directly, or encourage or solicit payment from the Student directly or through any channels
                        other than those made available via the Teorem Marketplace, with the aim of bypassing the Teorem Marketplace or avoiding
                        payment of fees owed to Teorem;
                    </u>
                </ul>
                <ul>3. Manipulate the price of any item offered within the Services or interfere with other User’s listings;</ul>
                <ul>4. Disrupt or otherwise interfere in any way with any other User’s use of the Services;</ul>
                <ul>
                    5. Transmit any User-generated Content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, offensive,
                    obscene, pornographic, lewd, lascivious, or otherwise objectionable, as determined by Teorem;
                </ul>
                <ul>
                    6. Circumvent or manipulate the fee structure, billing process, or fees owed to Teorem or the Tutor; fail to deliver payment for
                    items purchased by you unless the Tutor has materially changed the tutoring services, a clear typographical error is made, or you
                    cannot reach the Tutor;
                </ul>
                <ul>7. Fail to deliver the tutoring services purchased from you unless such failure was caused by the Student.</ul>
                <ul>8. Use a name or language that Teorem, in its sole discretion, deems offensive;</ul>
                <ul>9. Post false, inaccurate, misleading, defamatory, or libelous information (including personal data);</ul>
                <ul>10. Post hateful or racially or ethnically objectionable User-generated Content;</ul>
                <ul>11. Post User-generated Content which infringes another's copyright, trademark or trade Secret;</ul>
                <ul>
                    12. Disclose any Confidential Information or personal data obtained from another User or in the course of your use of the Teorem
                    Marketplace;
                </ul>
                <ul>
                    13. Advertise or offer to sell any goods or services for any commercial purpose through the Teorem Marketplace which are not
                    relevant to the tutoring services offered through the Teorem Marketplace
                </ul>
                <ul>14. Take any action that may undermine the feedback or ratings system when it becomes available;</ul>
                <ul>
                    15. <u>Harass, threaten or intentionally embarrass or cause distress to another User, person or entity.</u>
                </ul>
                <ul>
                    16. Use another person’s personally identifiable information (such as the name, email address, identifying number, or credit card
                    number) pretending to be that person (impersonate another person);
                </ul>
                <ul>17. Promote, solicit, or participate in any multi-level marketing or pyramid schemes;</ul>
                <ul>18. Exploit children under 18 years of age;</ul>
                <ul>19. Engage in disruptive activity such as sending multiple messages in an effort to monopolize your position;</ul>
                <ul>20. Adapt, alter, license, sublicense or translate the Service for your own personal or commercial use;</ul>
                <ul>21. Introduce viruses, worms, Trojan horses and/or harmful code to the Teorem Marketplace;</ul>
                <ul>22. Obtain unauthorized access to any computer system through the Teorem Marketplace;</ul>
                <ul>23. Transfer your Teorem account (including feedback) and login credentials to another party without Teorem’s consent;</ul>
                <ul>24. Harvest or otherwise collect information about Users, including email addresses, without their consent;</ul>
                <ul>
                    25. Invade the privacy of any person, including but not limited to posting personally identifying or otherwise private information
                    about a person without their consent (or their parent’s consent in the case of a child under 18 years of age);
                </ul>
                <ul>26. Solicit personal data from children under 18 years of age;</ul>
                <ul>27. Violate any federal, state, local, or international law or regulation;</ul>
                <ul>28. Encourage conduct that would constitute a criminal or civil offense;</ul>
                <ul>
                    29. Engage in or promote the illegal trafficking of persons, including procurement of commercial sex acts and use of forced labor
                    in the provision of the tutoring services.
                </ul>
                <ul>30. Use the Services for the purpose of cheating instead of facilitating learning;</ul>
                <ul>
                    31. Inquire about the hiring of or hire Tutors to complete assignments, write papers, take quizzes or otherwise do work on your
                    behalf;
                </ul>
                <ul>
                    32. Use Services for any purpose that violates the academic honesty policy or other conduct policies of your school, university,
                    academic institution or workplace;
                </ul>
                <ul>
                    33. Sell, resell, license, sublicense, distribute, rent, lease, or otherwise provide access to the Services to any third party
                    except to the extent explicitly authorized in writing by Teorem;
                </ul>
                <ul>34. Schedule lessons without the Student’s knowledge and confirmation with the aim of receiving payment.</ul>
            </div>
        </LandingWrapper>
    );
};

export default Conduct;
