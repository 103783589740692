import './locales/localizationService';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-input-2/lib/style.css';
import './styles/App.scss';
import 'rc-time-picker/assets/index.css';

import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import THEME from './app/constants/theme';
import LocaleProvider from './app/features/localization/providers/LocaleProvider';
import { persistor, store } from './app/store/store';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import packageJson from '../package.json';

const container = document.getElementById('root');
const root = createRoot(container!);

Sentry.init({
    dsn: 'https://8534c83ad78751caa36eb2677bae0447@o4507770093109248.ingest.de.sentry.io/4507770674806864',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    environment: process.env.REACT_APP_ENV,
    release: `${packageJson.name}@${packageJson.version}`,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={THEME}>
                <LocaleProvider>
                    <Router
                        getUserConfirmation={() => {
                            /* Empty callback to block the default browser prompt */
                        }}
                    >
                        <App />
                        <ToastContainer />
                    </Router>
                </LocaleProvider>
            </ThemeProvider>
        </PersistGate>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
