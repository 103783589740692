import { ButtonPrimaryGradient } from './ButtonPrimaryGradient';

interface Props {
    title: string;
    desc: string;
    showBtn: boolean;
}

const HeroSection = (props: Props) => {
    const { title, desc, showBtn } = props;

    const titleArr = title.split(' ');

    return (
        <div className="landing__hero mt-10">
            <div className="landing__hero__title animated-title">
                {titleArr.map((word, index) => (
                    <span key={word} className={'landing--fluid--title margin-span-title'} dangerouslySetInnerHTML={{ __html: word + '' }} />
                ))}
            </div>

            {/*<div dangerouslySetInnerHTML={{__html:title}} className="landing__hero__title landing--fluid--title"></div>*/}
            <div dangerouslySetInnerHTML={{ __html: desc }} className="landing__hero__subtitle landing--fluid--sm type--color--secondary"></div>
            {showBtn ? <ButtonPrimaryGradient className="btn btn--lg mt-10">Try for free</ButtonPrimaryGradient> : <></>}
        </div>
    );
};

export default HeroSection;
