import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import React, { Suspense, useState } from 'react';

import { degreesToRadians } from '../utils/radians';
import { NovaGumica } from './Novagumica';
import { NovaOlovka } from './NovaOlovka';
import { NoveKnjige } from './NoveKnjige';
import { NoviTrokut } from './NoviTrokut';

export const Canvas3D = () => {
    const gumica = [degreesToRadians(10), degreesToRadians(45), degreesToRadians(25)];
    const olovka = [degreesToRadians(10), degreesToRadians(-40), degreesToRadians(15)];
    const trokut = [0, 0, degreesToRadians(12)];
    const knjige = [0, degreesToRadians(-45), degreesToRadians(-9)];

    const [objectsLoaded, setObjectsLoaded] = useState(false);

    const onLoaded = () => {
        setObjectsLoaded(true);
    };
    return (
        <>
            {/*{objectsLoaded && <Canvas onLoadedData={() => setObjectsLoaded(true)} className={`landing__section w--100 video-container appear-animation`} style={{ width:'100vw', height:'2000px', position:'absolute', zIndex:1}}>*/}
            {/*    <ambientLight intensity={5} />*/}
            {/*    <directionalLight position={[0, 0, 1]} intensity={4} />*/}
            {/*    <PerspectiveCamera makeDefault position={[0,0,Math.PI]} fov={75}/>*/}
            {/*    <OrbitControls enableZoom={false} autoRotate={false} enableRotate={false} enablePan={false} />*/}
            {/*    /!*<axesHelper args={[100]}/>*!/*/}
            {/*    <Suspense fallback={null}>*/}
            {/*        <NovaOlovka*/}
            {/*            rotation={olovka}*/}
            {/*            directionX={-1}*/}
            {/*            directionY={1}*/}
            {/*        />*/}

            {/*        <NoviTrokut*/}
            {/*            rotation={trokut}*/}
            {/*            directionX={-1}*/}
            {/*            directionY={1}*/}
            {/*        />*/}

            {/*        <NovaGumica*/}
            {/*            rotation={gumica}*/}
            {/*            directionX={-1}*/}
            {/*            directionY={1}*/}
            {/*        />*/}
            {/*        <NoveKnjige*/}
            {/*            rotation={knjige}*/}
            {/*            directionX={3}*/}
            {/*            directionY={1}*/}
            {/*        />*/}

            {/*    </Suspense>*/}
            {/*</Canvas>}*/}

            <Suspense fallback={null}>
                <Canvas
                    className={`landing__section w--100 video-container appear-animation`}
                    style={{ width: '100vw', height: '2000px', position: 'absolute', zIndex: 1 }}
                >
                    <ambientLight intensity={5} />
                    <directionalLight position={[0, 0, 1]} intensity={4} />
                    <PerspectiveCamera makeDefault position={[0, 0, Math.PI]} fov={75} />
                    <OrbitControls enableZoom={false} autoRotate={false} enableRotate={false} enablePan={false} />

                    <NovaOlovka rotation={olovka} directionX={-1} directionY={1} />
                    <NoviTrokut rotation={trokut} directionX={-1} directionY={1} />
                    <NovaGumica rotation={gumica} directionX={-1} directionY={1} />
                    <NoveKnjige rotation={knjige} directionX={3} directionY={1} />
                </Canvas>
            </Suspense>
        </>
    );
};
