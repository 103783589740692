import { useEffect } from 'react';

import LandingWrapper from '../components/LandingWrapper';
import { Helmet } from 'react-helmet';
import META_DATA from '../../../constants/metaData';
import ROUTES from '../../../constants/routes';

const RefundPolicy_HR = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const isHrMeta = window.location.pathname.includes('/hr');
    return (
        <LandingWrapper>
            <Helmet>
                <title>{META_DATA.REFUND.TITLE[isHrMeta ? 'HR' : 'EN']}</title>
                <meta lang={isHrMeta ? 'hr_HR' : 'en_US'} name="description" content={META_DATA.REFUND.DESCRIPTION[isHrMeta ? 'HR' : 'EN']} />
                <link rel="alternate" hrefLang="en_US" href={window.location.origin + ROUTES.REFUND_POLICY.EN} />
                <link rel="alternate" hrefLang="hr_HR" href={window.location.origin + ROUTES.REFUND_POLICY.HR} />
            </Helmet>
            <div className="privacy">
                <h1>
                    <strong>Politika povrata novca i plaćanja</strong>
                </h1>
                <p>Vaša kupnja i plaćanje predavanja/grupnih tečajeva bit će regulirana ovim Pravilima povrata novca i plaćanja.</p>
                <p>
                    MOLIMO VAS DA GA PAŽLJIVO PROČITATE PRIJE PLAĆANJA BUDUĆI DA ODREĐUJE VAŠA PRAVA U VEZI S VAŠIM KUPNJAMA, UKLJUČUJUĆI BITNA
                    OGRANIČENJA I ISKLJUČENJA.
                </p>
                <p>
                    Teorem nastoji osigurati jasno razumijevanje financijskih odnosa između Studenata i Instruktora s obzirom na usluge koje pružamo.
                    Ova se politika primjenjuje na sve usluge i značajke koje su dostupne putem web-mjesta i bilo koje povezane aplikacije Teorem za
                    mobilne uređaje (zajedno, "Teorem Marketplace"). Pojmovi napisani velikim početnim slovom koji se ovdje koriste i koji nisu
                    definirani imat će odgovarajuća značenja koja su im pripisana u<a href={'/hr/uvjeti-koristenja'}> Uvjetima pružanja usluge</a>.
                </p>
                <h2>
                    <strong>Transakcije Instruktor-Student</strong>
                </h2>
                <p>
                    Teorem olakšava sklapanje ugovora između korisnika (Studenta i Instruktora) pružajući platformu za traženje, isporuku i plaćanje
                    usluga podučavanja. Teorem je samo platforma i ni u kojem slučaju neće biti strana u ugovorima između Instruktora i Studenata,
                    niti će biti odgovoran za bilo koju drugu uslugu ili materijale koje pruža nastavnik. Teorem ni na koji način neće biti odgovoran
                    za praćenje transakcija između Studenata i Instruktora.
                </p>
                <h2>
                    <strong>Valuta naplate i devizni rizik</strong>
                </h2>
                <p>
                    Transakcije plaćanja bit će obrađene u jednoj od dostupnih valuta prikazanih na blagajni. Trenutno su valute dostupne za plaćanje
                    EUR.
                </p>
                <p>
                    Radi vaše udobnosti cijene mogu biti prikazane i u vašoj lokalnoj valuti koja nije EUR. U tom će slučaju platne transakcije biti
                    obrađene u eurima. Cijene koje vidite u valutama koje nisu EUR su indikativne (samo za smjernice).
                </p>
                <p>
                    Imajte na umu da ako je vaša odabrana valuta (a) različita od EUR ili (b) drugačija od valute za naplatu vašeg načina plaćanja,
                    vaše plaćanje može biti obrađeno izvan vaše zemlje prebivališta. Kao rezultat toga, mogu se primjenjivati određene naknade, kao
                    što su naknade za međunarodne transakcije i devizne naknade, a iznos naveden na vašem bankovnom izvodu može se razlikovati od
                    iznosa prikazanog na blagajni.
                </p>
                <p>Teorem neće biti odgovoran za takve naknade i fluktuacije tečaja i Teorem se ovime odriče svake odgovornosti u tom pogledu.</p>
                <p>Predlažemo da se obratite svojoj banci ili kartičarskoj kući ako imate bilo kakvih pitanja o naknadama ili važećim tečajevima.</p>
                <h2>Provizija za Teorem</h2>
                <p>
                    Instruktorima naplaćujemo naknadu za korištenje naših usluga ("Provizija za teorem"). Provizija za Teorem naplaćuje se za svaku
                    lekciju koju vodi Instruktor po stopi navedenoj na Teorem Marketplaceu. Teorem zadržava pravo promjene Provizije za Teorem u bilo
                    kojem trenutku i obavijestit će Instruktore o svim promjenama naknada prije nego što stupe na snagu.
                </p>
                <h2>Povrat novca</h2>
                <p>
                    Zahtjev za povrat morate poslati na <a href={'mailto: support@teorem.co'}> support@teorem.co</a>.
                </p>
                <p>
                    Ako postoje olakotne okolnosti koje su uzrokovale da propustite lekciju ili je otkažete u kratkom roku, ti se povrati mogu
                    izvršiti prema odluci Instruktora, ali Instruktor to nije obvezan učiniti.
                </p>
                <p>
                    Teorem nije odgovoran za bilo koju vrstu naknade koju naplaćuju banke ili treća strana za obradu plaćanja i neće je pokriti
                    prilikom pokretanja povrata.
                </p>
                <p>
                    U slučaju da odaberete opciju povrata novca, njegova brzina i dostupnost povrata sredstava na vašem bankovnom računu ovisit će
                    isključivo o uvjetima i politici vaše banke. Točan iznos povrata ovisit će o tečaju između eura i valute vašeg načina plaćanja
                    važećem na datum pokretanja povrata.
                </p>
                <p>Možete dobiti samo jedan povrat po zahtjevu.</p>
                <p>
                    Povrat novca neće biti izvršen ako je Korisnik izgubio i/ili zaboravio svoje vjerodajnice za prijavu (korisničko ime i lozinku),
                    što je rezultiralo gubitkom podataka, privremenim gubitkom pristupa Korisničkom računu ili bilo kakvim drugim neugodnostima za
                    Korisnika.
                </p>
                <p>
                    Neće se izvršiti povrat novca u slučaju da je Korisnik izgubio vezu zbog nedostatka pristupa Internetu, vraćanja na tvorničke
                    postavke ili ažuriranja.
                </p>
                <p>
                    U slučaju da niste uspjeli podnijeti zahtjev za povrat u navedenom roku, još uvijek možete zatražiti od nas da vratimo kredite na
                    stanje vašeg Teorem računa. Takvi bodovi mogu se kasnije koristiti za zakazivanje lekcija kod bilo kojeg drugog učitelja.
                </p>
                <h2>Postupak povrata novca</h2>
                <p>
                    Da biste zatražili povrat novca, pošaljite e-poruku na <a href={'mailto: support@teorem.co'}> support@teorem.co</a> sa sljedećim
                    podacima:
                </p>
                <ul>
                    <li>Datum i vrijeme lekcije</li>
                    <li>Razlog traženog povrata</li>
                    <li>Ime vašeg Instruktora</li>
                </ul>
                <p>Odluka o povratu novca donosi se u roku od 30 kalendarskih dana.</p>
                <p>U najvećoj mjeri dopuštenoj zakonom, bilo koji povrat novca u bilo kojem trenutku ovisi o našem vlastitom nahođenju.</p>
                <h2>Storniranje</h2>
                <p>
                    U slučaju storniranja od strane banaka ili trećih strana za obradu plaćanja u vezi s vašom kupnjom bilo koje lekcije, slažete se
                    da možemo obustaviti pristup bilo kojem i svim računima koje imate kod nas.
                </p>
                <p>
                    Naknade i troškove koji nastanu kao rezultat storniranja ili drugih sporova oko plaćanja koje pokrenete vi, vaša banka ili
                    procesor plaćanja treće strane, snosite sami.
                </p>
                <h2>Istekao račun</h2>
                <p>
                    U slučaju da se niste prijavili na svoj Teorem račun dulje od 180 dana, vaš račun će biti obustavljen i vaš preostali saldo će
                    isteći.
                </p>
                <h2>Brisanje računa</h2>
                <p>Ako odlučite izbrisati svoj Teorem račun, vaš preostali saldo će isteći.</p>
                <p>
                    U slučaju da Teorem obustavi ili ukine vaš račun zbog kršenja bilo kojeg od naših pravila, razumijete i slažete se da nećete
                    dobiti nikakav povrat novca ili kompenzaciju za neiskorišteni saldo ili zakazane lekcije.
                </p>
                <h2>Usluge plaćanja</h2>
                <p>
                    Obradu plaćanja na Teorem Marketplaceu pružaju procesori plaćanja trećih strana uključujući, ali ne ograničavajući se na
                    Braintree, Stripe, PayPal, Skrill, Payoneer i Wise, što nam omogućuje (a) fakturiranje Studentima umjesto izravne obrade kredita/
                    podaci o debitnoj kartici; i (b) omogućiti isplate Instruktorima.
                </p>
                <p>
                    Radi izbjegavanja sumnje, plaćanje koje je izvršio Student Teoremu zadovoljit će Studentovu obvezu u pogledu plaćanja Instruktoru
                    za usluge podučavanja pružene putem Teorem Marketplacea.
                </p>
                <p>
                    Koristimo integrirani iframe za naplatu od procesora plaćanja trećih strana, a Instruktori dobivaju plaćanje za svaku potvrđenu
                    lekciju. Sukladno tome, sve su transakcije osigurane budući da procesori plaćanja trećih strana koriste zaštitu SSL enkripcijom.
                </p>
                <h2>Vaši podatci</h2>
                <p>
                    Slažete se i razumijete da informacije o naplati, koje dajete za plaćanje lekcija putem Teorem Marketplacea, obrađuju samo
                    procesori plaćanja treće strane. Teorem ne prikuplja, pohranjuje niti na drugi način obrađuje vaše podatke o naplati.
                </p>
                <p>
                    Slažete se da Teorem neće biti odgovoran za propuste treće strane da adekvatno zaštiti takve informacije. Obrada plaćanja
                    podliježe odredbama, uvjetima i politici privatnosti trećih strana za obradu plaćanja uz ovu politiku.
                </p>
                <p>
                    Prihvaćate da možemo promijeniti procesore plaćanja treće strane i zahtijevati od njih da prenesu vaše podatke drugim pružateljima
                    usluga koji šifriraju vaše podatke pomoću tehnologije sloja sigurnih utičnica (SSL) ili druge usporedive sigurnosne tehnologije.
                </p>
                <h2>Plaćanja</h2>
                <p>Načini plaćanja dostupni na Teorem Marketplaceu su sljedeći:</p>
                <ul>
                    <li>Visa/Mastercard</li>
                    <li>PayPal</li>
                    <li>Apple Pay</li>
                    <li>Google Pay</li>
                </ul>
                <p>Sva plaćanja za korištenje Teorem Marketplacea i usluge podučavanja vršit će se putem Teorem Marketplacea.</p>
                <p>
                    Studenti su odgovorni za sve transakcijske naknade koje se odnose na plaćanja za korištenje Teorem Marketplacea i usluga
                    podučavanja.
                </p>
                <h2>Isplate</h2>
                <p>
                    Instruktori dobivaju isplate za svaku potvrđenu lekciju. Isplate se automatski distribuiraju Instruktorima jednom tjedno,
                    četvrtkom. Isplata za vašu prvu lekciju bit će raspodijeljena do 2 tjedna nakon završetka lekcije.
                </p>
                <p>
                    Imajte na umu da procesori plaćanja trećih strana naplaćuju naknadu za svaku transakciju. Takve transakcijske naknade bit će
                    oduzete od odgovarajućeg iznosa isplate i bit će prikazane unutar funkcionalnosti Teorem Marketplace.
                </p>
                <h2>Automatsko ponovno punjenje</h2>
                <p>
                    Kada kupite paket sati na Teorem Marketplaceu, može vam se naplaćivati na ponavljajućoj osnovi ("Automatsko ponovno punjenje").
                    Kada vam ostane jedan ili manje sati s Instruktorom, isti paket sati koji ste prethodno kupili bit će dodan na stanje vašeg računa
                    terećenjem vašeg spremljenog načina plaćanja.
                </p>
                <p>
                    Automatsko ponovno punjenje može se otkazati u bilo kojem trenutku. Postavke automatskog punjenja također se mogu promijeniti.
                    Možete odabrati broj sati koje želite automatski nadopuniti za svakog Instruktora mijenjanjem ovih postavki u odjeljku "Načini
                    plaćanja" u postavkama vašeg računa.
                </p>
                <h2>Ostalo</h2>
                <p>
                    Teorem nije banka ili bilo koja vrsta financijske institucije i ne pruža nikakve financijske usluge ili savjete. Sva plaćanja
                    izvršena putem Teorem Marketplacea vrše se isključivo u svrhu pristupa, olakšavanja i pružanja usluga podučavanja između Studenata
                    i Instruktora. Takva plaćanja ne smiju se vršiti i koristiti za bilo kakve prijevarne, kriminalne ili nezakonite aktivnosti kako
                    je definirano zakonom.
                </p>
            </div>
        </LandingWrapper>
    );
};

export default RefundPolicy_HR;
