import React from 'react';
import { useTranslation } from 'react-i18next';
import { LuBookOpenCheck } from 'react-icons/lu';

import ITutorItem from '../../../store/slices/tutor/tutorService';
import { getHostName } from '../../../utils/getHostName';
import { ButtonPrimaryGradient } from './ButtonPrimaryGradient';
import CustomSubjectList from './CustomSubjectList';
import ImageCircle from './ImageCircle';
import { StarRating } from './StarRating';
import localizeRoute from '../../../utils/localizeRoute';
import ROUTES from '../../../constants/routes';
import APP_ROUTES from '../../../constants/appRoutes';

interface Props {
    tutor: ITutorItem;
    onClick?: () => void;
    className?: string;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}

export const PricingTutorCard = (props: Props) => {
    const { tutor, onClick, onMouseEnter, onMouseLeave, className } = props;
    const { t, i18n } = useTranslation();
    const hostname = process.env.REACT_APP_HOSTNAME || getHostName();

    return (
        <div
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            style={{ width: '450px', background: 'white' }}
            className={`${className ? className : ''} flex flex--col  mt-2 tutor-card`}
            onClick={onClick}
        >
            <div className=" flex flex--row flex--ai--start ">
                <div className="tutor-list__item__img w--unset mr-2">
                    {tutor.profileImage ? (
                        <img
                            style={{
                                width: '150px',
                                height: '150px',
                            }}
                            className="align--center d--b"
                            src={`${tutor.profileImage}`}
                            alt="tutor-profile-pic"
                        />
                    ) : (
                        <ImageCircle
                            className="align--center d--b mb-4"
                            imageBig={true}
                            initials={`${tutor.firstName.charAt(0)}${tutor.lastName.charAt(0)}`}
                        />
                    )}
                </div>

                <div className="flex flex--col flex--grow">
                    <div className="flex flex--row flex--jc--space-between type--md">
                        <h3 className="">{tutor.firstName.split(' ')[0] + ' ' + tutor.lastName.charAt(0) + '.'}</h3>
                        <h3>{tutor.minPrice + ' ' + tutor.currencyCode + ' / h'}</h3>
                    </div>

                    <div className="flex flex--row flex--jc--space-between mb-2">
                        <span>
                            {tutor.currentOccupation.length > 30 ? tutor.currentOccupation.substring(0, 30) + '...' : tutor.currentOccupation}
                        </span>
                        <span></span>
                    </div>

                    {tutor.averageGrade !== 0 && tutor.numberOfGrades > 0 ? (
                        <>
                            <div className="flex flex--row flex--jc--start flex--ai--center">
                                <span>{tutor.averageGrade}&nbsp;</span>
                                <StarRating mark={tutor.averageGrade} size={'small'} />
                                <p className="ml-2 type--color--secondary">({tutor.numberOfGrades})</p>
                            </div>
                        </>
                    ) : (
                        <>
                            <span className="mb-3 type--italic type--color--secondary">{t('SEARCH_TUTORS.NO_REVIEWS')}</span>
                        </>
                    )}

                    {tutor.completedLessons > 0 ? (
                        <div className="flex flex--row flex--ai--center mb-3 mt-1">
                            <LuBookOpenCheck size={20} color={'#7e6cf2'} />
                            <span className="d--ib ml-1">
                                {tutor.completedLessons} {t('SEARCH_TUTORS.COMPLETED_LESSONS')}
                            </span>
                        </div>
                    ) : (
                        <>
                            <span className="mb-3 type--italic type--color--secondary">{t('SEARCH_TUTORS.NO_COMPLETED_LESSONS')}</span>
                        </>
                    )}

                    <div className="subjects-container mb-3">
                        <CustomSubjectList subjects={tutor.subjects} numOfSubjectsShown={1}></CustomSubjectList>
                    </div>
                </div>
            </div>

            <div className="mt-2 recommended-card-truncate">
                <p>{tutor.aboutTutor}</p>
            </div>

            <div className="flex flex--row flex--jc--space-evenly mt-3">
                <a className="type--color--white" href={`${hostname}${localizeRoute(APP_ROUTES.LOGIN, i18n)}`}>
                    <ButtonPrimaryGradient className={'btn btn--base pl-8 pr-8 pt-3 pb-3 h--100 w--100'}>
                        {t('SEARCH_TUTORS.BOOK_LESSON')}
                    </ButtonPrimaryGradient>
                </a>
                <a
                    className="btn btn--base btn--ghost--bordered type--wgt--extra-bold pl-8 pr-8 pt-3 pb-3"
                    href={`${hostname}${localizeRoute(APP_ROUTES.SEARCH_TUTOR, i18n).replace(':tutorSlug', tutor.slug)}`}
                >
                    {t('SEARCH_TUTORS.VIEW_PROFILE')}
                </a>
            </div>
        </div>
    );
};
