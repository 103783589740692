import { useEffect } from 'react';

import LandingWrapper from '../components/LandingWrapper';
import { Helmet } from 'react-helmet';
import META_DATA from '../../../constants/metaData';
import ROUTES from '../../../constants/routes';

const Cookie_HR = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const isHrMeta = window.location.pathname.includes('/hr');
    return (
        <LandingWrapper>
            <Helmet>
                <title>{META_DATA.FALLBACK.TITLE[isHrMeta ? 'HR' : 'EN']}</title>
                <meta lang={isHrMeta ? 'hr_HR' : 'en_US'} name="description" content={META_DATA.FALLBACK.DESCRIPTION[isHrMeta ? 'HR' : 'EN']} />
                <link rel="alternate" hrefLang="en_US" href={window.location.origin + ROUTES.COOKIE.EN} />
                <link rel="alternate" hrefLang="hr_HR" href={window.location.origin + ROUTES.COOKIE.HR} />
            </Helmet>
            <div className="privacy">
                <h1>TEOREM POLITIKA KOLAČIĆA</h1>
                <p>
                    Želimo da znate više o našoj upotrebi kolačića. Zbog toga je ovaj dokument napisan. Objašnjava što su kolačići, kako ih koristimo,
                    vaše izbore u vezi kolačića i dodatne informacije o kolačićima.
                </p>
                <p>
                    Ova politika kolačića je dio naših <a href={'/hr/uvjeti-koristenja'}> Uvjeta korištenja</a> i{' '}
                    <a href={'/hr/politika-privatnosti'}> Politike privatnosti</a>, zajedno s vašim pristankom koji čini naš sporazum s vama.
                </p>
                <p>
                    <b>Možete nas kontaktirati koristeć kontakt detalje navedene niže</b>
                </p>
                <p>
                    Teorem Ltd, 45 Palace Court, London, W2 4LS, Ujedinjeno Kraljevstvo, email:{' '}
                    <a href={'mailto:legal@teorem.co'}> legal@teorem.co</a>
                </p>
                <p>
                    <b>Teorem koristi kolačiće </b>
                </p>
                <p>
                    Teorem (“mi”, “nas”,“naše”) koristi kolačiće i druge tehnologije na web stranici{' '}
                    <a href={'http://www.teorem.hr'}> http://www.teorem.hr</a>. Korištenjem usluga i klikom na odgovarajuće gumbe na našim bannerima
                    pristajete na korištenje kolačića u svrhe koje opisujemo u ovoj politici.
                </p>
                <p>
                    <b>Kolačići su...</b>
                </p>
                <p>
                    Male datoteke koje sadrže dijelove teksta koje web-mjesto koje posjetite šalje vašem pregledniku. Omogućuju našem poslužitelju da
                    Vam pružili informacije koje su prilagođene Vašim potrebama kada sljedeći put budete koristili Usluge. Obično, vaš preglednik javi
                    našem sustavima jesu li neke od datoteka kolačića postavljene na vašem uređaju i kao rezultat toga možemo analizirati informacije
                    koje nam datoteke kolačića daju.
                </p>
                <h3>Vrste kolačića i svrhe u koje ih Teorem koristi</h3>
                <p>Teorem koristi kolačiće u sljedeće svrhe kada pristupate Uslugama:</p>
                <ul>
                    <p>
                        <b>1. Strogo neophodno</b>
                    </p>
                    <p>Naša web stranica ne može ispravno funkcionirati bez ovih kolačića. Ovi kolačići ne pohranjuju nikakve osobne podatke.</p>
                    <p>
                        Neki od kolačića su kolačići sesije i bit će izbrisani kada izađete s web stranice, drugi se mogu pohraniti do 2 godine nakon
                        vašeg zadnjeg posjeta našoj web stranici prije nego što se automatski izbrišu
                    </p>
                    <p>
                        <b>2. Izvedba, analitika i istraživanje</b>
                    </p>
                    <p>
                        Kolačići nam pomažu saznati koliko dobro funkcionira naša web stranica i dodaci na različitim lokacijama. Također koristimo
                        kolačiće kako bismo razumjeli kako korisnici stupaju u interakciju s uslugama, poboljšali sadržaj i usluge te implementirali
                        značajke koje poboljšavaju korisničko iskustvo.
                    </p>
                    <p>
                        Ti kolačići mogu biti pohranjeni do 2 godine nakon vašeg zadnjeg posjeta našoj web stranici prije nego što se automatski
                        izbrišu.
                    </p>
                    <p>Imajte na umu da su neki od kolačića trajni kolačići koji se neće automatski izbrisati i stoga ih treba izbrisati ručno</p>
                    <p>
                        <b>3. Funkcionalni</b>
                    </p>
                    <p>Kolačići koji poboljšavaju vaše iskustvo s našim uslugama i omogućuju nam da održavamo vaše korisničke postavke. </p>
                    <p>
                        Neki od kolačića su kolačići sesije i bit će izbrisani kada izađete s web stranice, drugi su trajni kolačići koji se neće
                        automatski izbrisati i stoga ih treba izbrisati ručno.
                    </p>
                    <p>
                        <b>4. Ciljanje</b>
                    </p>
                    <p>
                        Teorem može koristiti kolačiće kako bi vam pružio relevantnije oglašavanje kada koristite naše Usluge. Kolačići će nam
                        omogućiti da saznamo više o vašim prethodnim posjetima Teoremu i vašoj aktivnosti unutar Teorema, uključujući datum i vrijeme
                        posjeta, vrijeme provedeno na web stranici kao i pregledane stranice i prethodno posjećene web stranice.
                    </p>
                    <p>
                        Neki od kolačića su kolačići sesije i bit će izbrisani kada izađete s web stranice, drugi bi mogli biti pohranjeni do 3 godine
                        nakon vašeg zadnjeg posjeta našoj web stranici prije nego što se automatski izbrišu.
                    </p>
                    <p>Imajte na umu da su neki od kolačića trajni kolačići koji se neće automatski izbrisati i stoga ih treba izbrisati ručno.</p>
                </ul>
                <p>Kolačići trećih strana LinkedIna, Googlea, Vimea, Binga, Snapchata, Facebooka i YouTubea postavljaju se na našoj web stranici.</p>
                <h3>Upravljanje kolačićima</h3>
                <p>
                    Osim ako ne odaberete drugačije, možemo pohraniti i obraditi samo one kolačiće koji su neophodni za rad naše Web stranice. Ako ne
                    želite da postavljamo kolačiće na vaš uređaj, možete odbiti dati svoj početni pristanak prilagođavanjem postavki u skočnom prozoru
                    prikazanom tijekom vašeg prvog posjeta web stranici ili ih odbijte kasnije.
                </p>
                <p>
                    Vrste kolačića moći ćete provjeriti klikom na opciju "Prikaži detalje" i odabrati određene vrste kolačića označavanjem polja u
                    skočnom prozoru. Označavanjem opcije “Dopusti sve kolačiće” slažete se s korištenjem kolačića za sve gore navedene svrhe. Ako se
                    ne slažete s korištenjem kolačića, možete nastaviti koristiti usluge strogo uz potrebne kolačiće samo klikom na “Spremi postavke”.
                </p>
                <p>
                    Imajte na umu da će odbijanje određenih vrsta kolačića, osim onih koji su nužni, utjecati na vaše iskustvo na našoj Web stranici,
                    budući da neće biti personalizacije i prepoznavanja uređaja tijekom naknadnih upotreba Web stranice.
                </p>
                <p>
                    Također možete promijeniti ili povući svoju suglasnost u bilo kojem trenutku tako da nas kontaktirate na našu kontakt e-mail
                    adresu. Također, vaš web preglednik omogućuje kontrolu i ograničavanje kolačića na uređaju.
                </p>
                <p>
                    Imajte na umu da jednostavno onemogućavanje svih kolačića ili svih naših kolačića u postavkama vašeg preglednika može dovesti do
                    toga da određeni dijelovi ili značajke naše web stranice ne rade. Stoga preporučujemo korištenje postavki kolačića na našoj web
                    stranicu umjesto onemogućavanja svih kolačića u vašem web pregledniku.
                </p>
                <h3>Pronađite više informacija o kolačićima </h3>
                <p>
                    Da biste dobili više informacija o razdobljima zadržavanja, možete skenirati web stranicu koristeći usluge skeniranja kolačića,
                    kao što su:{' '}
                </p>
                <li>
                    All About Cookies: <a href={'https://allaboutcookies.org/'}>https://allaboutcookies.org/</a>
                </li>
                <li>
                    Network Advertising Initiative: <a href={'https://www.networkadvertising.org/ '}> https://www.networkadvertising.org/ </a>
                </li>
            </div>
        </LandingWrapper>
    );
};

export default Cookie_HR;
