import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';

import diners from '../../../../assets/images/diners-club.png';
import ipad from '../../../../assets/images/ipad-hand-hold.png';
import logoWhite from '../../../../assets/images/logoWhite.svg';
import maestro from '../../../../assets/images/maestro.png';
import masterCard from '../../../../assets/images/mastercard.png';
import stripe from '../../../../assets/images/stripe-white.svg';
import visa from '../../../../assets/images/visa.png';
import localizeRoute from '../../../utils/localizeRoute';
import ROUTES from '../../../constants/routes';

const Footer = () => {
    const history = useHistory();
    const { t, i18n } = useTranslation();

    return (
        <div className="landing__footer">
            <div className="landing__footer__content">
                <NavLink className="landing__navigation__logo d--b flex--shrink mt-10" to={localizeRoute(ROUTES.HOME, i18n)}>
                    <img src={logoWhite} alt="logo" />
                </NavLink>
                <div className="type--color--white mt-10 type--md">
                    {t('LANDING.FOOTER.TITLE_TEXT')}
                    <a className="type--color--white type--underline" href="mailto: support@teorem.co">
                        support@teorem.co
                    </a>
                </div>
                <div className="flex flex--wrap w--100 type--color--white ">
                    <div className="mr-20">
                        {window.location.hostname === 'teorem.hr' || window.location.hostname === 'www.teorem.hr' ? (
                            <div className={'flex flex--row'}>
                                <div className={'mt-8 mr-20'}>
                                    <div className="type--wgt--bold ">Teorem Učitelj d.o.o.</div>
                                    <div className="type--wgt--bold">
                                        Masarykova ulica 25 <br /> 10 000 Zagreb, Hrvatska
                                    </div>
                                    <div className="type--wgt--bold">support@teorem.co</div>
                                    {/*<div className='type--wgt--bold'>IBAN</div>*/}
                                    {/*<div className='type--wgt--bold'>POSLOVNA BANKA</div>*/}
                                </div>
                                <div className={'mt-8 flex--ai--end'}>
                                    <div className="type--wgt--bold">OIB: 19894745169</div>
                                    <div className="type--wgt--bold">MBS: 081562234</div>
                                    <div className="type--wgt--bold">Trgovački sud u Zagrebu</div>
                                    <div>Temeljni kapital EUR 2,600.00 u cijelosti uplaćen</div>
                                    <div className="type--wgt--bold">Predsjednik uprave Marko Vučić</div>
                                </div>
                            </div>
                        ) : (
                            <>
                                {/*<div>{t('LANDING.FOOTER.ADDRESS')}</div>*/}
                                <div>{t('LANDING.FOOTER.MOBILE')}</div>
                                <div className="type--wgt--bold mt-8">{t('LANDING.FOOTER.APP_NAME')}</div>
                                <div>{t('LANDING.FOOTER.EMAIL')}</div>
                            </>
                        )}
                    </div>
                    <div className="mr-20">
                        <div className="type--wgt--bold mt-8">{t('LANDING.FOOTER.LEGAL.TITLE')}</div>
                        <div className="cur--pointer" onClick={() => history.push(localizeRoute(ROUTES.TERMS, i18n))}>
                            {t('LANDING.FOOTER.LEGAL.LEGAL')}
                        </div>
                        <div className="cur--pointer" onClick={() => history.push(localizeRoute(ROUTES.PRIVACY, i18n))}>
                            {t('LANDING.FOOTER.LEGAL.PRIVACY')}
                        </div>
                    </div>
                    <div className="">
                        <div className="type--wgt--bold mt-8">{t('LANDING.FOOTER.PAYMENT.TITLE')}</div>
                        <div className="landing__footer__cards">
                            <img src={stripe} alt="stripe logo" />
                            <img src={visa} alt="visa logo" />
                            <img src={masterCard} alt="master card logo" />
                            <img src={diners} alt="diners logo" />
                            <img src={maestro} alt="maestro card logo" />
                        </div>
                    </div>
                </div>
                <div className="mt-10 mb-4 flex flex--wrap w--100 type--color--white">
                    <span className="mr-20 type--color--white">&#169; Teorem Ltd 2021-{new Date().getFullYear()}</span>

                    <div className="mr-20 type--color--white">
                        <div className="flex flex--wrap w--80 type--color--white">
                            <div className="mr-10 cur--pointer" onClick={() => history.push(localizeRoute(ROUTES.LEGAL_CENTER, i18n))}>
                                {t('LANDING.FOOTER.LEGAL.LEGAL_CENTER')}
                            </div>
                            <div className="mr-10">&bull;</div>
                            <div className="mr-10 cur--pointer align-self-center" onClick={() => history.push(localizeRoute(ROUTES.PRIVACY, i18n))}>
                                {t('LANDING.FOOTER.LEGAL.PRIVACY')}
                            </div>
                            <div className="mr-10">&bull;</div>
                            <div className="mr-10 cur--pointer" onClick={() => history.push(localizeRoute(ROUTES.COOKIE, i18n))}>
                                Cookie Policy
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="landing__footer__img">
                <img src={ipad} alt="footer-photo" />
            </div>
        </div>
    );
};

export default Footer;
