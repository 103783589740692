import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import earnings from '../../../../assets/images/earnings.png';
import logoPurpleBlack from '../../../../assets/images/logo-purple-black.jpg';
import video1 from '../../../../assets/videos/video1.mov';
import { Canvas3D } from '../../../components/Canvas3D';
import { getHostName } from '../../../utils/getHostName';
import FAQGroup from '../components/FAQGroup';
import Footer from '../components/Footer';
import ReviewCard from '../components/ReviewCard';
import TextCard from '../components/TextCard';
import IFAQItem from '../interfaces/IFAQItem';
import profileIllustration1 from './../../../../assets/images/instruktor-ante.jpg';
import profileIllustration2 from './../../../../assets/images/instruktorica-dunja.jpg';
import icon1 from './../../../../assets/images/TEOREM_IKONE-01.jpg';
import icon3 from './../../../../assets/images/TEOREM_IKONE-03.jpg';
import icon4 from './../../../../assets/images/TEOREM_IKONE-04.jpg';
import icon5 from './../../../../assets/images/TEOREM_IKONE-05.jpg';
import icon6 from './../../../../assets/images/TEOREM_IKONE-06.jpg';
import icon8 from './../../../../assets/images/TEOREM_IKONE-08.jpg';
import { setRegistrationModalOpen } from '../../../store/slices/auth/authSlice';
import { useAppDispatch } from '../../../store/hooks';
import { Helmet } from 'react-helmet';
import META_DATA from '../../../constants/metaData';
import ROUTES from '../../../constants/routes';

const BecomeTutor = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const hostname = process.env.REACT_APP_HOSTNAME || getHostName();

    const cardText1 = {
        text: t('SCHOOL.REVIEW_1.TEXT'),
        name: t('SCHOOL.REVIEW_1.NAME'),
        role: t('SCHOOL.REVIEW_1.OCCUPATION'),
        numberOfLessons: t('BECOME_A_TUTOR.REVIEW_1.NUMBER_OF_LESSONS'),
    };
    const cardText2 = {
        text: t('SCHOOL.REVIEW_2.TEXT'),
        name: t('SCHOOL.REVIEW_2.NAME'),
        role: t('SCHOOL.REVIEW_2.OCCUPATION'),
        numberOfLessons: t('BECOME_A_TUTOR.REVIEW_2.NUMBER_OF_LESSONS'),
    };

    const FAQContent: IFAQItem[] = [
        {
            id: 'faq-2',
            question: t('SCHOOL.FAQ.QUESTION_2'),
            answer: t('SCHOOL.FAQ.ANSWER_2'),
        },
        {
            id: 'faq-3',
            question: t('SCHOOL.FAQ.QUESTION_3'),
            answer: t('SCHOOL.FAQ.ANSWER_3'),
        },
        {
            id: 'faq-4',
            question: t('SCHOOL.FAQ.QUESTION_4'),
            answer: t('SCHOOL.FAQ.ANSWER_4'),
        },
        {
            id: 'faq-5',
            question: t('SCHOOL.FAQ.QUESTION_5'),
            answer: t('SCHOOL.FAQ.ANSWER_5'),
            link: t('SCHOOL.FAQ.LINK_5'),
            key: 'SCHOOL.FAQ.ANSWER_5',
        },
        {
            id: 'faq-7',
            question: t('SCHOOL.FAQ.QUESTION_7'),
            answer: t('SCHOOL.FAQ.ANSWER_7'),
        },
    ];

    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const isMobile = window.innerWidth < 800;
    const isHrMeta = window.location.pathname.includes('/hr');
    return (
        <>
            <Helmet>
                <title>{META_DATA.SCHOOL.TITLE[isHrMeta ? 'HR' : 'EN']}</title>
                <meta lang={isHrMeta ? 'hr_HR' : 'en_US'} name="description" content={META_DATA.SCHOOL.DESCRIPTION[isHrMeta ? 'HR' : 'EN']} />
                <link rel="alternate" hrefLang="en_US" href={window.location.origin + ROUTES.SCHOOL.EN} />
                <link rel="alternate" hrefLang="hr_HR" href={window.location.origin + ROUTES.SCHOOL.HR} />
            </Helmet>
            {!isMobile && <Canvas3D />}
            <div className="landing">
                <div className="landing__content">
                    {/* Content */}

                    <div className="landing__hero">
                        <img className="mb-10" src={logoPurpleBlack} alt="logo" />
                        <div
                            dangerouslySetInnerHTML={{ __html: t('SCHOOL.HERO.TITLE') }}
                            className="landing__hero__title__no__anim landing--fluid--title w--680--max"
                        ></div>
                        <div
                            dangerouslySetInnerHTML={{ __html: t('SCHOOL.HERO.SUBTITLE') }}
                            className="landing__hero__subtitle__no__anim landing--fluid--sm type--color--secondary w--400--max"
                        ></div>
                    </div>

                    <a
                        style={{ zIndex: 2 }}
                        id="zapocni-danas-skole-1"
                        className=" btn btn--primary btn--lgr"
                        onClick={() => dispatch(setRegistrationModalOpen(true))}
                    >
                        {t('BECOME_A_TUTOR.BUTTON_1')}
                    </a>

                    <div className="landing__section mt-20 w--100">
                        <video className="w--100" autoPlay muted loop playsInline>
                            <source src={video1} type="video/mp4" />
                        </video>
                    </div>

                    <TextCard
                        title={'Teorem Vam daje sav alat za rad <i>online</i>'}
                        className="mt-14"
                        desc={'Pružamo Vam sve što trebate za podučavanje <i>online</i>. Pomažemo Vam da pronađete učenike i upravljate rasporedom.'}
                    />
                    <div className="mt-30">
                        <div className="row row--adaptive">
                            <div className="col col-6 col-md-6 col-lg-4">
                                <div className="landing__grid__card">
                                    <img className="image-icon" src={icon3} alt="grid" />
                                    <div className="type--lg type--wgt--bold mb-4 landing-title-fs">{t('SCHOOL.CARD_1.TITLE')}</div>
                                    <div className="type--color--tertiary type--md landing-subtitle-fs">{t('SCHOOL.CARD_1.SUBTITLE')}</div>
                                </div>
                            </div>

                            <div className="col col-6 col-md-6 col-lg-4">
                                <div className="landing__grid__card">
                                    <img className="image-icon" src={icon1} alt="grid" />
                                    <div className="type--lg type--wgt--bold mb-4 landing-title-fs">{t('SCHOOL.CARD_2.TITLE')}</div>
                                    <div className="type--color--tertiary type--md landing-subtitle-fs">{t('SCHOOL.CARD_2.SUBTITLE')}</div>
                                </div>
                            </div>

                            <div className="col col-6 col-md-6 col-lg-4">
                                <div className="landing__grid__card">
                                    <img className="image-icon" src={icon5} alt="grid" />
                                    <div className="type--lg type--wgt--bold mb-4 landing-title-fs">{t('SCHOOL.CARD_3.TITLE')}</div>
                                    <div className="type--color--tertiary type--md landing-subtitle-fs">{t('SCHOOL.CARD_3.SUBTITLE')}</div>
                                </div>
                            </div>

                            <div className="col col-6 col-md-6 col-lg-4">
                                <div className="landing__grid__card">
                                    <img className="image-icon" src={icon6} alt="grid" />
                                    <div className="type--lg type--wgt--bold mb-4 landing-title-fs">{t('SCHOOL.CARD_4.TITLE')}</div>
                                    <div className="type--color--tertiary type--md landing-subtitle-fs">{t('SCHOOL.CARD_4.SUBTITLE')}</div>
                                </div>
                            </div>

                            <div className="col col-6 col-md-6 col-lg-4">
                                <div className="landing__grid__card">
                                    <img className="image-icon" src={icon8} alt="grid" />
                                    <div
                                        dangerouslySetInnerHTML={{ __html: t('SCHOOL.CARD_5.TITLE') }}
                                        className="type--lg type--wgt--bold mb-4 landing-title-fs"
                                    ></div>
                                    <div className="type--color--tertiary type--md landing-subtitle-fs">{t('SCHOOL.CARD_5.SUBTITLE')}</div>
                                </div>
                            </div>

                            <div className="col col-6 col-md-6 col-lg-4">
                                <div className="landing__grid__card">
                                    <img className="image-icon" src={icon4} alt="grid" />
                                    <div className="type--lg type--wgt--bold mb-4 landing-title-fs">{t('SCHOOL.CARD_6.TITLE')}</div>
                                    <div className="type--color--tertiary type--md landing-subtitle-fs">{t('SCHOOL.CARD_6.SUBTITLE')}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ReviewCard className="mt-30" data={cardText1} img={profileIllustration1} />

                    <TextCard
                        className="mt-30"
                        title={'Preglednost Vaše zarade i brza isplata'}
                        desc={'Isplata na IBAN Vašeg odabira svakog četvrtka umanjena za proviziju od 15%.'}
                    />

                    <div className="landing__section mt-30 w--100">
                        <img src={earnings} alt="calendar" className="landing__img" />
                    </div>
                    <p className="landing__title">{t('SCHOOL.SUBTITLE_1')}</p>
                    <ReviewCard className="mt-30" data={cardText2} img={profileIllustration2} />
                    <p className="landing__title">
                        Kako postati <i>online</i> instruktor na Teoremu?
                    </p>
                    <div className="mt-20">
                        <div className="flex flex--jc--start">
                            <div className="landing__steps__item">1</div>
                            <div className="mt-3 ml-6 type--color--secondary become-a-tutor-step">
                                {'Stvorite svoj korisnički račun i ispunite podatke.'}
                            </div>
                        </div>
                        <div className="flex flex--jc--start">
                            <div className="landing__steps__item">2</div>
                            <div className="mt-3 ml-6 type--color--secondary become-a-tutor-step">{t('SCHOOL.STEP_2')}</div>
                        </div>
                        <div className="flex flex--jc--start">
                            <div className="landing__steps__item last">3</div>
                            <div className="mt-3 ml-6 type--color--secondary become-a-tutor-step">{'Počnite podučavati!'}</div>
                        </div>
                    </div>
                    <div className="landing__section mt-30">
                        <a
                            id="zapocni-danas-skole-2"
                            className="btn btn--primary btn--l type--md"
                            onClick={() => dispatch(setRegistrationModalOpen(true))}
                        >
                            {t('BECOME_A_TUTOR.BUTTON_1')}
                        </a>
                    </div>
                    <p className="landing__title">{t('BECOME_A_TUTOR.SUBTITLE_3')}</p>
                    <FAQGroup data={FAQContent} />
                </div>
            </div>
            <Footer />
        </>
    );
};

export default BecomeTutor;
