import React from 'react';

interface Props {
    title: string;
    desc: string;
    className?: string;
    descClassName?: string;
}

const TextCard = (props: Props) => {
    const { title, desc, className } = props;

    return (
        <div className={`landing__text-card ${className ? className : ''}`}>
            <h1 dangerouslySetInnerHTML={{ __html: title }} className="landing__text-card__title mb-4"></h1>
            <p
                style={{}}
                dangerouslySetInnerHTML={{ __html: desc }}
                className={`landing__text-card__description type--color--secondary landing--fluid--sm  ${className ? className : ''}`}
            ></p>
        </div>
    );
};

export default TextCard;
