import { i18n } from 'i18next';
import IRoute from '../types/IRoute';

export default function localizeRoute(route: IRoute, i18n: i18n) {
    const i18nLanguage = i18n.language.toUpperCase() as 'EN' | 'HR';
    if (i18nLanguage !== 'EN' && i18nLanguage !== 'HR') {
        return route['EN'];
    }
    return route[i18n.language.toUpperCase() as 'EN' | 'HR'];
}
