/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/noveKnjige.glb 
*/

import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import React, { useEffect, useRef, useState } from 'react';

export function NoveKnjige(props) {
    const { nodes, materials } = useGLTF('/noveKnjige.glb');

    const [scrollY, setScrollY] = useState(0);
    const ref = useRef(null); // Use Group or Mesh depending on what you're referencing

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    useEffect(() => {
        console.log('ROTATION', props.rotation);
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useFrame(() => {
        if (ref.current) {
            const dirX = props.directionX ? props.directionX : 1;
            const dirY = props.directionY ? props.directionY : 1;
            const rotationFactor = 0.001;
            const moveFactor = 0.0005;
            ref.current.rotation.x = scrollY * rotationFactor;
            ref.current.rotation.y = scrollY * rotationFactor;
            ref.current.position.x = 1.3 + dirX * scrollY * moveFactor;
            ref.current.position.y = 1.2 + dirY * scrollY * moveFactor;
            ref.current.position.z = dirY * scrollY * moveFactor;
        }
    });

    return (
        <group {...props} dispose={null}>
            <group ref={ref} scale={0.0008} rotation={props.rotation}>
                <mesh geometry={nodes.Object_7_1.geometry} material={materials['Custom (2)']} />
                <mesh geometry={nodes.Object_7_2.geometry} material={materials['Custom (4)']} />
                <mesh geometry={nodes.Object_7_3.geometry} material={materials.Material_3} />
                <mesh geometry={nodes.Object_7_4.geometry} material={materials['Custom (1)']} />
            </group>
        </group>
    );
}

useGLTF.preload('/noveKnjige.glb');
