const ROUTES = {
    HOME: {
        EN: '/',
        HR: '/hr',
    },
    HOW_IT_WORKS: {
        EN: '/online-tutoring',
        HR: '/hr/online-instrukcije',
    },
    BECOME_TUTOR: {
        EN: '/become-tutor',
        HR: '/hr/postani-instruktor',
    },
    PRICING: {
        EN: '/pricing',
        HR: '/hr/cijene',
    },
    TERMS: {
        EN: '/terms',
        HR: '/hr/uvjeti-koristenja',
    },
    PRIVACY: {
        EN: '/privacy-policy',
        HR: '/hr/politika-privatnosti',
    },
    TAX: {
        EN: '/tax',
        HR: '/hr/porez',
    },
    SCHOOL: {
        EN: '/schools',
        HR: '/hr/skole',
    },
    COOKIE: {
        EN: '/cookie-policy',
        HR: '/hr/politika-kolacica',
    },
    LEGAL_CENTER: {
        EN: '/legal-center',
        HR: '/hr/pravni-centar',
    },
    REFUND_POLICY: {
        EN: '/refund-and-payment-policy',
        HR: '/hr/politika-povrata-i-placanja',
    },
    USER_CODE_OF_CONDUCT: {
        EN: '/user-code-of-conduct',
        HR: '/hr/kodeks-ponasanja-korisnika',
    },
    BLOG: {
        EN: 'https://start.teorem.co/en-us',
        HR: 'https://start.teorem.co/hr',
    },
};

export default ROUTES;
