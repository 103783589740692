import { FieldProps, useField } from 'formik';
import { useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { t } from 'i18next';
import { useAppSelector } from '../store/hooks';

interface TextFieldType extends FieldProps {
    className?: string;
    inputType?: string;
    name: string;
    disabled?: boolean;
    openTooltip?: () => void;
}

export default function MyPhoneInput(props: TextFieldType) {
    const { form } = props;
    const [field, meta] = useField(props as any);
    const errorText = meta.error && meta.touched ? meta.error : '';
    const { selectedCountry } = useAppSelector((state) => state.countryMarket);

    const currentValue = form.values.phoneNumber;

    useEffect(() => {
        const selectedFlag = document.getElementsByClassName('selected-flag');
        for (let i = 0; i < selectedFlag.length; i++) {
            const element = selectedFlag[i] as HTMLElement;
            element.tabIndex = -1;
        }
    });

    // set font size based on device
    const isMobile = window.innerWidth <= 768;
    const fontSize = isMobile ? 'small' : 'medium';

    return (
        <>
            <PhoneInput
                {...field}
                {...props}
                specialLabel={t('REGISTER.FORM.PHONE_NUMBER')}
                country={selectedCountry?.abrv.toLowerCase()}
                value={currentValue}
                onChange={(phone) => {
                    form.setFieldValue('phoneNumber', phone);
                    // form.setFieldTouched('phoneNumber', true, false);
                    // form.validateField('phoneNumber');
                }}
                onBlur={() => form.setFieldTouched(field.name)}
                disabled={props.disabled}
                onClick={() => {
                    if (props.openTooltip) props.openTooltip();
                }}
                inputStyle={{
                    width: '100%',
                    borderRadius: '8px',
                }}
                searchStyle={{ fontSize: fontSize }}
                dropdownStyle={{ fontSize: fontSize, textAlign: 'left' }}
                containerStyle={{ textAlign: 'left' }}
            />
            {errorText ? <div className="field__validation">{errorText}</div> : null}
        </>
    );
}
