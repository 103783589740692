import gloria from '../../../../assets/images/gloria-logo.jpeg';
import hrt from '../../../../assets/images/HRT-logo.jpeg';
import index from '../../../../assets/images/index-logo.png';
import srednja from '../../../../assets/images/srednja-logo.svg';
import vecernji from '../../../../assets/images/vecernji-logo.svg';

interface CarouselItem {
    imgUrl: string;
    imgTitle: string;
    url: string;
}

export const carouselItems: CarouselItem[] = [
    {
        imgUrl: vecernji,
        imgTitle: 'Večernji list',
        url: 'https://www.vecernji.hr/vijesti/povezite-se-brzo-i-jednostavno-s-najboljim-online-instruktorima-u-hrvatskoj-1751752',
    },
    {
        imgUrl: srednja,
        imgTitle: 'Srednja.hr',
        url: 'https://www.srednja.hr/matura/isprobala-sam-online-instrukcije-iz-matematike-rjesavali-smo-trik-pitanje-s-prijasnje-mature',
    },
    {
        imgUrl: hrt,
        imgTitle: 'HRT',
        url: 'https://www.facebook.com/HRTvijesti/videos/574920077465129',
    },
    {
        imgUrl: gloria,
        imgTitle: 'Gloria',
        url: 'https://www.gloria.hr/gl/promo/povezite-se-brzo-i-jednostavno-s-najboljim-online-instruktorima-u-hrvatskoj-15438107',
    },
    {
        imgUrl: index,
        imgTitle: 'Index',
        url: 'https://www.index.hr/magazin/clanak/povezite-se-brzo-i-jednostavno-s-najboljim-online-instruktorima-u-hrvatskoj/2544729.aspx',
    },
];
