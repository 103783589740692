import { useEffect } from 'react';

import LandingWrapper from '../components/LandingWrapper';
import { Helmet } from 'react-helmet';
import META_DATA from '../../../constants/metaData';
import ROUTES from '../../../constants/routes';

const Cookie = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const isHrMeta = window.location.pathname.includes('/hr');
    return (
        <LandingWrapper>
            <Helmet>
                <title>{META_DATA.FALLBACK.TITLE[isHrMeta ? 'HR' : 'EN']}</title>
                <meta lang={isHrMeta ? 'hr_HR' : 'en_US'} name="description" content={META_DATA.FALLBACK.DESCRIPTION[isHrMeta ? 'HR' : 'EN']} />
                <link rel="alternate" hrefLang="en_US" href={window.location.origin + ROUTES.COOKIE.EN} />
                <link rel="alternate" hrefLang="hr_HR" href={window.location.origin + ROUTES.COOKIE.HR} />
            </Helmet>
            <div className="privacy">
                <h1>TEOREM COOKIE POLICY</h1>
                <p>
                    We would like you to know more about our use of cookies. That is why this document was drafted. It explains what cookies are, how
                    we use cookies, your choices regarding cookies and further information about cookies.
                </p>
                <p>
                    This Cookie Policy is a part of our <a href={'/en/terms'}> Terms of Service</a> and{' '}
                    <a href={'/en/privacy-policy'}> Privacy Policy</a>, together with your consents comprising our agreement with you.
                </p>
                <p>
                    <b>You can contact us using the contact details provided below.</b>
                </p>
                <p>
                    Teorem Ltd, 45 Palace Court, London, W2 4LS, United Kingdom, email: <a href={'mailto:legal@teorem.co'}> legal@teorem.co</a>
                </p>
                <p>
                    <b>Teorem does use cookies </b>
                </p>
                <p>
                    Teorem (“us”, “we”, or “our”) uses cookies and other technologies on the{' '}
                    <a href={'http://www.teorem.co'}> http://www.teorem.co</a> Website. By using the Services and clicking the respective buttons on
                    our banners, you consent to the use of cookies for the purposes we describe in this policy.
                </p>
                <p>
                    <b>Cookies are...</b>
                </p>
                <p>
                    Those small files comprising bits of text that are sent to your browser by a website you visit. They enable our server to provide
                    you with the information that is customized to your needs when you use the Services the next time. Usually, your browser tells our
                    systems if any cookies files were placed on your device and as a result, we may analyze the information the cookies files give us.
                </p>
                <h3>Types of cookies and purposes Teorem uses them </h3>
                <p>Teorem uses the cookies for the following purposes when you access the Services:</p>
                <ul>
                    <p>
                        <b>1. Strictly Necessary</b>
                    </p>
                    <p>
                        Our Website cannot function properly without these cookies. These cookies do not store any personally identifiable
                        information.
                    </p>
                    <p>
                        Some of the cookies are session cookies and will be deleted as you exit the Website, others could be stored for up to 2 years
                        after your last visit to our Website before they are automatically deleted.
                    </p>
                    <p>
                        <b>2. Performance, Analytics and Research</b>
                    </p>
                    <p>
                        Cookies help us learn how well our Website and plugins perform in different locations. We also use cookies to understand how
                        users interact with the Services, improve the content, and the Services and implement features that improve the user’s
                        experience.
                    </p>
                    <p>Those cookies could be stored for up to 2 years after your last visit to our Website before they are automatically deleted.</p>
                    <p>
                        Please note that some of the cookies are persistent cookies that will not be deleted automatically and therefore should be
                        deleted manually.
                    </p>
                    <p>
                        <b>3. Functional </b>
                    </p>
                    <p>Cookies that enhance your experience with our Services and allow us to maintain your user’s preferences. </p>
                    <p>
                        Some of the cookies are session cookies and will be deleted as you exit the Website, others are persistent cookies that will
                        not be deleted automatically and therefore should be deleted manually.
                    </p>
                    <p>
                        <b>4. Targeting</b>
                    </p>
                    <p>
                        Teorem may use cookies in order to provide you with more relevant advertising when you use the Services. Cookies will enable
                        us to know more about your prior visits to Teorem and your activity within Teorem, including the date and time of visits, time
                        spent on the Website as well as the pages viewed and websites previously visited.
                    </p>
                    <p>
                        Some of the cookies are session cookies and will be deleted as you exit the Website, others could be stored for up to 3 years
                        after your last visit to our Website before they are automatically deleted.
                    </p>
                    <p>
                        Please note that some of the cookies are persistent cookies that will not be deleted automatically and therefore should be
                        deleted manually.
                    </p>
                </ul>
                <p>Third-party cookies of LinkedIn, Google, Vimeo, Bing, Snapchat, Facebook and YouTube are being set by our Website.</p>
                <h3>Managing Cookies </h3>
                <p>
                    Unless you choose otherwise, we can store and process only those cookies that are necessary for the operation of our Website. If
                    you don’t want us to place cookies on your device, you can refuse to give your initial consent by customizing your preferences in
                    the pop-up window shown during your first visit to the Website or opt out later.
                </p>
                <p>
                    You will be able to check types of cookies by clicking the “Show details” option and choose specific types of cookies by ticking
                    boxes in the pop-up window. By ticking “Allow all cookies”, you agree with the use of cookies for all purposes mentioned above. If
                    you don't agree with the use of cookies, you can continue using the Services with strictly necessary cookies only by clicking
                    “Save preferences”.
                </p>
                <p>
                    Please note that declining specific types of cookies other than necessary will affect your experience on our Website since there
                    will be no personalization and recognition of devices during the subsequent uses of the Website.
                </p>
                <p>
                    You can also change or withdraw your consent at any time by contacting us at our contact email address. Also, your web browser
                    allows you to control and limit cookies on the device.
                </p>
                <p>
                    Additionally, please keep in mind that simply disabling all cookies or all of our cookies in your browser settings may lead to
                    certain sections or features of our Website not working. Therefore, we recommend using the Cookie Settings on our Website instead
                    of disabling all cookies in your web browser.
                </p>
                <h3>Find more information about cookies </h3>
                <p>To get more information about retention periods, you can scan the Website using cookie scanner services, such as: </p>
                <li>
                    All About Cookies: <a href={'https://allaboutcookies.org/'}>https://allaboutcookies.org/</a>
                </li>
                <li>
                    Network Advertising Initiative: <a href={'https://www.networkadvertising.org/ '}> https://www.networkadvertising.org/ </a>
                </li>
            </div>
        </LandingWrapper>
    );
};

export default Cookie;
