import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import LandingWrapper from '../components/LandingWrapper';
import localizeRoute from '../../../utils/localizeRoute';
import ROUTES from '../../../constants/routes';
import { Helmet } from 'react-helmet';
import META_DATA from '../../../constants/metaData';

const LegalCenter = () => {
    const history = useHistory();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const isHrMeta = window.location.pathname.includes('/hr');
    return (
        <LandingWrapper>
            <Helmet>
                <title>{META_DATA.LEGAL_CENTER.TITLE[isHrMeta ? 'HR' : 'EN']}</title>
                <meta lang={isHrMeta ? 'hr_HR' : 'en_US'} name="description" content={META_DATA.LEGAL_CENTER.DESCRIPTION[isHrMeta ? 'HR' : 'EN']} />
                <link rel="alternate" hrefLang="en_US" href={window.location.origin + ROUTES.LEGAL_CENTER.EN} />
                <link rel="alternate" hrefLang="hr_HR" href={window.location.origin + ROUTES.LEGAL_CENTER.HR} />
            </Helmet>
            <div className="legal-center">
                <h1>{t('LEGAL_CENTER.TITLE')}</h1>
                <p>{t('LEGAL_CENTER.SUBTITLE')}</p>
                <br />
                <ul>
                    <li className="cur--pointer nav-link" onClick={() => history.push(localizeRoute(ROUTES.TERMS, i18n))}>
                        {t('LANDING.FOOTER.LEGAL.LEGAL')}
                    </li>
                    <li className="cur--pointer nav-link" onClick={() => history.push(localizeRoute(ROUTES.PRIVACY, i18n))}>
                        {t('LANDING.FOOTER.LEGAL.PRIVACY')}
                    </li>
                    <li className="cur--pointer nav-link" onClick={() => history.push(localizeRoute(ROUTES.TAX, i18n))}>
                        {t('LEGAL_CENTER.TAX_TITLE')}
                    </li>
                    <li className="cur--pointer nav-link" onClick={() => history.push(localizeRoute(ROUTES.REFUND_POLICY, i18n))}>
                        {t('LANDING.FOOTER.LEGAL.REFUND')}
                    </li>
                    <li className="cur--pointer nav-link" onClick={() => history.push(localizeRoute(ROUTES.USER_CODE_OF_CONDUCT, i18n))}>
                        {t('LANDING.FOOTER.LEGAL.CONDUCT')}
                    </li>
                </ul>
            </div>
        </LandingWrapper>
    );
};

export default LegalCenter;
