import { useEffect, useRef, useState } from 'react';
import { LiaLanguageSolid } from 'react-icons/lia';

import { useLocaleContext } from '../providers/LocaleProvider';
import { useAppSelector } from '../../../store/hooks';

interface Props {
    onTop: boolean;
    className?: string;
    color?: string;
    colorOnTop?: string;
}

const LocaleSelector = (props: Props) => {
    const { className, color, onTop, colorOnTop } = props;
    const { setLangModalOpen } = useLocaleContext();
    const { selectedLanguage } = useAppSelector((state) => state.lang);
    const { selectedCountry } = useAppSelector((state) => state.countryMarket);

    const languageRef = useRef<HTMLDivElement>(null);

    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    const textColor = onTop ? colorOnTop ?? 'white' : color ?? 'gray';

    return (
        <div
            ref={languageRef}
            className={`${className} language`}
            onClick={() => {
                setLangModalOpen(true);
            }}
        >
            <LiaLanguageSolid color={textColor} className={isMobile ? 'icon--base' : 'icon--md'} size={100} />
            <span className="language__label" style={{ color: textColor }}>
                {selectedCountry?.abrv.toUpperCase()} - {selectedLanguage?.abrv.toUpperCase()}
            </span>
        </div>
    );
};

export default LocaleSelector;
