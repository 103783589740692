import ILevel from '../interfaces/ILevel';

export const levels: ILevel[] = [
    {
        id: '6324ece3-5cc4-48f3-8e9b-be757549eb35',
        abrv: 'primary-school',
        name: 'Primary School',
    },
    {
        id: 'bb589332-eb38-4455-9259-1773bf88d60a',
        abrv: 'high-school',
        name: 'High School',
    },
    {
        id: '76a23a36-e937-476a-a70c-a22680da17ea',
        abrv: 'matura-prep',
        name: 'Matura Prep',
    },
    // {
    //   id: "86211553-d94f-47b7-990b-67587f8c91bc",
    //   abrv:"university",
    //   name: "University"
    // }
];
