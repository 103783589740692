import { Redirect } from 'react-router';
import { Route, Switch, useHistory } from 'react-router-dom';
import Home from './app/features/landing/pages/Home';
import BecomeTutor from './app/features/landing/pages/BecomeTutor';
import Pricing from './app/features/landing/pages/Pricing';
import Terms from './app/features/landing/pages/Terms';
import Terms_HR from './app/features/landing/pages/Terms_HR';
import Privacy_HR from './app/features/landing/pages/Privacy_HR';
import Privacy from './app/features/landing/pages/Privacy';
import Tax from './app/features/landing/pages/Tax';
import Cookie from './app/features/landing/pages/Cookie';
import Cookie_HR from './app/features/landing/pages/Cookie_HR';
import LegalCenter from './app/features/landing/pages/LegalCenter';
import RefundPolicy from './app/features/landing/pages/RefundPolicy';
import RefundPolicy_HR from './app/features/landing/pages/RefundPolicy_HR';
import Conduct from './app/features/landing/pages/Conduct';
import Conduct_HR from './app/features/landing/pages/Conduct_HR';
import { useLocaleContext } from './app/features/localization/providers/LocaleProvider';
import useMount from './app/utils/useMount';
import LocaleSelectModal from './app/features/localization/components/LocaleSelectModal';
import RegistrationModal from './app/features/auth/components/RegistrationModal';
import SchoolCampaign from './app/features/landing/pages/SchoolCampaign';
import { useLazyGetCountriesQuery } from './app/store/slices/countryMarket/countryService';
import { useAppDispatch } from './app/store/hooks';
import { setCountries } from './app/store/slices/countryMarket/countryMarketSlice';
import { useLazyGetLanguagesQuery } from './app/store/slices/lang/langService';
import { setLanguages } from './app/store/slices/lang/langSlice';
import LoginModal from './app/features/auth/components/LoginModal';
import ResetPasswordModal from './app/features/auth/components/ResetPasswordModal';
import ROUTES from './app/constants/routes';

export default function App() {
    const history = useHistory();
    const { initLang } = useLocaleContext();
    const dispatch = useAppDispatch();

    const [getCountries] = useLazyGetCountriesQuery();
    const [getLanguages] = useLazyGetLanguagesQuery();

    async function initializeLocale() {
        try {
            const [res, resLang] = await Promise.all([getCountries().unwrap(), getLanguages().unwrap()]);

            dispatch(setCountries(res));
            dispatch(setLanguages(resLang));
        } catch (e) {
            console.log(e);
        }
    }

    useMount(() => {
        initializeLocale();
        window.scrollTo(0, 0);
        initLang((lang) => {
            console.log('initLang', lang);
            if (window.location.pathname !== '/') {
                const curr = Object.values(ROUTES).find((route) => route.EN === window.location.pathname || route.HR === window.location.pathname);

                if (!curr) return;

                const currPath = curr[lang.toUpperCase() as 'EN' | 'HR'];

                if (currPath === window.location.pathname) {
                    return;
                }

                history.push(curr[lang.toUpperCase() as 'EN' | 'HR']);
                return;
            }

            if (lang === 'hr') {
                history.replace('/hr');
            }
        });
    });

    return (
        <>
            <Switch>
                <Route path={ROUTES.PRICING.EN} exact>
                    <Pricing />
                </Route>
                <Route path={ROUTES.PRICING.HR} exact>
                    <Pricing />
                </Route>
                <Route path={ROUTES.HOW_IT_WORKS.EN} exact>
                    <Redirect to={ROUTES.HOME.EN} />
                </Route>
                <Route path={ROUTES.HOW_IT_WORKS.HR} exact>
                    <Redirect to={ROUTES.HOME.HR} />
                </Route>
                <Route path={ROUTES.BECOME_TUTOR.EN} exact>
                    <BecomeTutor />
                </Route>
                <Route path={ROUTES.BECOME_TUTOR.HR} exact>
                    <BecomeTutor />
                </Route>
                <Route path={ROUTES.TERMS.EN} exact>
                    <Terms />
                </Route>
                <Route path={ROUTES.TERMS.HR} exact>
                    <Terms_HR />
                </Route>
                <Route path={ROUTES.PRIVACY.EN} exact>
                    <Privacy />
                </Route>
                <Route path={ROUTES.PRIVACY.HR} exact>
                    <Privacy_HR />
                </Route>
                <Route path={ROUTES.TAX.EN} exact>
                    <Tax />
                </Route>
                <Route path={ROUTES.TAX.HR} exact>
                    <Tax />
                </Route>
                <Route path={ROUTES.COOKIE.EN} exact>
                    <Cookie />
                </Route>
                <Route path={ROUTES.COOKIE.HR} exact>
                    <Cookie_HR />
                </Route>
                <Route path={ROUTES.SCHOOL.EN} exact>
                    <SchoolCampaign />
                </Route>
                <Route path={ROUTES.SCHOOL.HR} exact>
                    <SchoolCampaign />
                </Route>
                <Route path={ROUTES.LEGAL_CENTER.EN} exact>
                    <LegalCenter />
                </Route>
                <Route path={ROUTES.LEGAL_CENTER.HR} exact>
                    <LegalCenter />
                </Route>
                <Route path={ROUTES.REFUND_POLICY.EN} exact>
                    <RefundPolicy />
                </Route>
                <Route path={ROUTES.REFUND_POLICY.HR} exact>
                    <RefundPolicy_HR />
                </Route>
                <Route path={ROUTES.USER_CODE_OF_CONDUCT.EN} exact>
                    <Conduct />
                </Route>
                <Route path={ROUTES.USER_CODE_OF_CONDUCT.HR} exact>
                    <Conduct_HR />
                </Route>
                <Route path={ROUTES.HOME.HR} exact>
                    <Home />
                </Route>
                <Route path={ROUTES.HOME.EN} exact>
                    <Home />
                </Route>
                <Route path="*">
                    <Redirect to={ROUTES.HOME.EN} />
                </Route>
            </Switch>
            <LocaleSelectModal />
            <RegistrationModal />
            <LoginModal />
            <ResetPasswordModal />
        </>
    );
}
