import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../store/hooks';
import useOutsideAlerter from '../../../utils/useOutsideAlerter';
import { allActiveSubjects } from './subjects';

interface Props {
    subjects: string[];
    numOfSubjectsShown?: number;
}

const CustomSubjectList = (props: Props) => {
    const { subjects, numOfSubjectsShown = 3 } = props;
    const { t } = useTranslation();
    const [showTooltip, setShowTooltip] = useState(false);

    const rangeSetterRef = useRef<HTMLDivElement>(null);

    const hideTooltip = () => {
        setShowTooltip(false);
    };

    useOutsideAlerter(rangeSetterRef, hideTooltip);

    const sortedSubjects = subjects.slice().sort((a, b) => {
        return a.localeCompare(b);
    });

    return (
        <div ref={rangeSetterRef} className="flex flex--wrap flex--ai--center">
            {sortedSubjects.slice(0, numOfSubjectsShown).map((subject) => (
                <span className="tag tag--primary" key={subject}>
                    {t(`SUBJECTS.${subject.replaceAll('-', '')}`)}
                </span>
            ))}

            {sortedSubjects.length > numOfSubjectsShown ? (
                <div className="pos--rel">
                    <span
                        className="type--color--brand cur--pointer"
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                    >
                        {`+ ${sortedSubjects.length - numOfSubjectsShown} ${t('SEARCH_TUTORS.SUBJECT_LIST.MORE')}`}{' '}
                    </span>
                    <div className={`tooltip--text ${showTooltip ? 'active' : ''}`}>
                        {sortedSubjects.slice(numOfSubjectsShown).map((subject) => (
                            <span className="type--color--brand" key={subject}>
                                {t(`SUBJECTS.${subject.replaceAll('-', '').replace(' ', '').toLowerCase()}`)}
                            </span>
                        ))}
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default CustomSubjectList;
