import { carouselItems } from './carousel-items';

export const Articles = () => {
    return (
        <div className={`logos`}>
            <div className="logos-slide">
                {carouselItems.map((card, index) => {
                    return (
                        <a key={card.url} href={card.url} target={'_blank'}>
                            {/*<div className='carousel-card'>*/}
                            <img className={'carousel-img'} src={card.imgUrl} alt={card.imgTitle} />
                            {/*</div>*/}
                        </a>
                    );
                })}
            </div>
            <div className="logos-slide">
                {carouselItems.map((card, index) => {
                    return (
                        <a key={card.url} href={card.url} target={'_blank'}>
                            {/*<div className='carousel-card'>*/}
                            <img className={'carousel-img'} src={card.imgUrl} alt={card.imgTitle} />
                            {/*</div>*/}
                        </a>
                    );
                })}
            </div>
        </div>
    );
};
