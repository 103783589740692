/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/novagumica.glb 
*/

import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import React, { useEffect, useRef, useState } from 'react';

export function NovaGumica(props) {
    const { nodes, materials } = useGLTF('/novagumica.glb');

    const [scrollY, setScrollY] = useState(0);
    const ref = useRef(null); // Use Group or Mesh depending on what you're referencing

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    useEffect(() => {
        console.log('ROTATION', props.rotation);
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useFrame(() => {
        if (ref.current) {
            const dirX = props.directionX ? props.directionX : 1;
            const dirY = props.directionY ? props.directionY : 1;
            const rotationFactor = 0.001;
            const moveFactor = 0.0015;
            ref.current.rotation.x = scrollY * rotationFactor;
            ref.current.rotation.y = scrollY * rotationFactor;
            ref.current.position.x = 1.2 + dirX * scrollY * moveFactor;
            ref.current.position.y = -0.2 + dirY * scrollY * moveFactor;
            ref.current.position.z = 1 + dirY * scrollY * moveFactor;
        }
    });

    return (
        <group rotation={props.rotation} dispose={null}>
            <group ref={ref} scale={0.004} rotation={props.rotation}>
                <mesh geometry={nodes.Object_3_1.geometry} material={materials['Custom (7)']} />
                <mesh geometry={nodes.Object_3_2.geometry} material={materials['Custom (8)']} />
                <mesh geometry={nodes.Object_3_3.geometry} material={materials.Material_6} />
            </group>
        </group>
    );
}

useGLTF.preload('/novagumica.glb');
