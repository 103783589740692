const APP_ROUTES = {
    SEARCH_TUTOR: {
        HR: '/hr/profil/:tutorSlug',
        EN: '/en/profile/:tutorSlug',
    },
    LOGIN: {
        HR: '/hr/login',
        EN: '/en/login',
    },
};

export default APP_ROUTES;
